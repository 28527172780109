var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-risk-protection","sidebar-class":"sidebar-lg","visible":_vm.riskProtectionSidebar.visible,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"shown":_vm.onShow,"change":function (val) { return _vm.mutateRiskProtectionSidebar({ visible: val }); },"hidden":_vm.clear},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"planning-sidebar-header px-2 py-1"},[_c('h4',[_vm._v("Adicionar nova proteção")])]),(_vm.loading.form)?[_c('b-row',{staticClass:"justify-content-md-center mt-2"},[_c('b-spinner',{attrs:{"big":"","variant":"primary"}})],1)]:_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de produto","label-for":"protection-product"}},[_c('v-select',{class:_vm.getSelectErrorClass(_vm.v$.product.$error),attrs:{"id":"protection-product","reduce":function (product) { return product.id; },"options":_vm.products,"loading":_vm.loading.products,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.product.required.$invalid)?_c('span',[_vm._v(" Você deve selecionar um tipo de produto ")]):_vm._e()])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Qual a necessidade de proteção?","label-for":"protection-need"}},[_c('v-select',{class:_vm.getSelectErrorClass(_vm.v$.need.$error),attrs:{"id":"protection-need","reduce":function (need) { return need.id; },"options":_vm.needs,"loading":_vm.loading.needs,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.need),callback:function ($$v) {_vm.need=$$v},expression:"need"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.need.required.$invalid)?_c('span',[_vm._v(" Você deve selecionar uma necessidade ")]):_vm._e()])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Selecione uma ou mais coberturas","label-for":"protection-coverages"}},[_c('v-select',{class:_vm.getSelectErrorClass(_vm.v$.coverage.$error),attrs:{"id":"protection-coverages","multiple":"","reduce":function (coverage) { return coverage.id; },"options":_vm.coverages,"loading":_vm.loading.coverages,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.coverage),callback:function ($$v) {_vm.coverage=$$v},expression:"coverage"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.coverage.required.$invalid)?_c('span',[_vm._v(" Você deve selecionar uma ou mais coberturas ")]):_vm._e()])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Seguradora","label-for":"protection-company"}},[_c('v-select',{class:_vm.getSelectErrorClass(_vm.v$.company.$error),attrs:{"id":"protection-company","reduce":function (company) { return company.id; },"options":_vm.companies,"loading":_vm.loading.companies,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}],null,true),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.company.required.$invalid)?_c('span',[_vm._v(" Você deve selecionar uma seguradora ")]):_vm._e()])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Indenização","label-for":"risk-compesation"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyFormat),expression:"moneyFormat"}],class:{ 'is-invalid': _vm.v$.compensation.$error },attrs:{"id":"amount"},model:{value:(_vm.compensation),callback:function ($$v) {_vm.compensation=$$v},expression:"compensation"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.compensation.required.$invalid)?_c('span',[_vm._v(" Você deve informar um valor de indenização válido ")]):_vm._e()])],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Prêmio","label-for":"risk-award"}},[_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyFormat),expression:"moneyFormat"}],class:{ 'is-invalid': _vm.v$.award.$error },attrs:{"id":"amount"},model:{value:(_vm.award),callback:function ($$v) {_vm.award=$$v},expression:"award"}}),_c('div',{staticClass:"invalid-feedback"},[(_vm.v$.award.required.$invalid)?_c('span',[_vm._v(" Você deve informar um valor de prémio válido ")]):_vm._e()])],1)],1)],1)],1),_c('div',{staticClass:"planning-sidebar-bottom px-1"},[_c('b-col',{staticClass:"mt-2 planing-sidebar-button",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":hide}},[_vm._v(" Fechar ")])],1),_c('b-col',{staticClass:"mt-2 planing-sidebar-button",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }