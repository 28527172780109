export const DEADLINE_TYPES = [
    {
      name: 'Dias'
    },
    {
      name: 'Meses'
    },
    {
      name: 'Anos'
    }
  ];