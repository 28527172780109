<template>
  <div>
    <WalletInvestmentGoalsSidebar
      :investment-goals="investmentGoals"
      :investment-goals-data="investmentGoalsData"
    />
    <InvestmentProfileSidebar
      :investment-profile="profileLevel"
      :set-investment-profile="setInvestmentProfile"
    />
    <!-- Filters -->
    <b-card>
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Gestão de ativos</h4>
            <span class="text-muted h5">Passo 07</span>
          </div>
        </div>
      </b-card-title>
      <template v-if="loading.form">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row class="pb-5">
          <b-col cols="12" md="6" class="asset-profile pr-2">
            <b-form-group
              class="mb-1"
              label="Perfil de investidor"
              label-for="investment-level"
            >
              <div class="investment-level">
                <img
                  :src="
                    require(`@/assets/images/financial_planning/profile_${profileLevel}.png`)
                  "
                  width="45px"
                />
                <div class="level-name">
                  <span
                    ><b>{{ profileLevelName }}</b></span
                  >
                  <b-button
                    variant="link"
                    style="padding: 0px; margin-top: 10px"
                    @click="editProfile"
                  >
                    Alterar perfil
                  </b-button>
                </div>
              </div>
            </b-form-group>
            <b-form-group
              class="mb-1"
              label="Metodologia de rebalanceamento"
              label-for="rebalancing-metodology"
            >
              <v-select
                id="rebalancing-metodology"
                v-model="rebalancingMetodology"
                :reduce="(metodology) => metodology.id"
                :options="rebalancingMetodologies"
                :loading="loading.rebalancingMetodologies"
                label="name"
                @input="saveData"
              />
            </b-form-group>
            <b-form-group
              label="Orientação para o cliente"
              label-for="orientations"
            >
              <b-form-textarea v-model="orientations" @blur="saveData" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="wallet-goals pl-2">
            <div class="d-flex justify-content-between align-items-center">
              <span>Metas de carteira</span>
              <b-button variant="primary" @click="editInvestmentGoals"
                >Editar</b-button
              >
            </div>
            <template v-for="goal in investmentGoals">
              <div class="mt-2">
                <div class="wallet-goal">
                  <div class="goal-title">
                    <div
                      class="dot"
                      :style="`background-color: ${goal.color};`"
                    ></div>
                    <span>{{ goal.title }}</span>
                  </div>
                  <span class="mr-1"
                    >{{ investmentGoalsData[goal.field] }}%</span
                  >
                </div>
              </div>
            </template>
            <div class="mt-2">
              <div class="wallet-goal">
                <div class="goal-title">
                  <strong style="margin-left: 10px;">Total</strong>
                </div>
                <strong class="mr-1">{{ percentageTotal }}%</strong>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="financial-previous-step">
          <b-button variant="outline-primary" @click="toStep('previous')">
            Voltar
          </b-button>
        </div>
        <div class="financial-next-step">
          <b-button variant="primary" @click="toStep('next')">
            Próximo
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useVuelidate from "@vuelidate/core";
import { VMoney } from "v-money";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import * as levelTypes from "@/constants/investment_level_types";
import WalletInvestmentGoalsSidebar from "../components/WalletInvestmentGoalsSidebar";
import InvestmentProfileSidebar from "../components/InvestmentProfileSidebar";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    WalletInvestmentGoalsSidebar,
    InvestmentProfileSidebar,
  },
  props: {
    toStep: Function,
  },
  directives: {
    money: VMoney,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: {
        form: false,
        rebalancingMetodologies: false,
      },
      profileLevel: levelTypes.MODERATE,
      rebalancingMetodology: undefined,
      orientations: undefined,
      investmentGoals: [
        { title: "Pós fixado", field: "postFixed", color: "#EC2020" },
        { title: "Inflação", field: "inflationRate", color: "#FFA413" },
        { title: "Prefixado", field: "preFixed", color: "#7B77CA" },
        { title: "Multimercado", field: "multimarket", color: "#FF4501" },
        { title: "Alternativos", field: "alternatives", color: "#13FFFF" },
        { title: "Internacional", field: "international", color: "#1147D9" },
        { title: "Renda variável", field: "variableIncome", color: "#EA06B5" },
      ],
      investmentGoalsData: {
        postFixed: 0,
        inflationRate: 0,
        preFixed: 0,
        multimarket: 0,
        alternatives: 0,
        international: 0,
        variableIncome: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      rebalancingMetodologies: types.ASSET_REBALANCING_METODOLOGIES,
    }),
    profileLevelName: function () {
      if (this.profileLevel === levelTypes.CONSERVATIVE) {
        return "Conservador";
      } else if (this.profileLevel === levelTypes.AGGRESSIVE) {
        return "Agressivo";
      }
      return "Moderado";
    },
    percentageTotal() {
      let total = 0;
      for (const goal in this.investmentGoalsData) {
        total += this.investmentGoalsData[goal];
      }
      return total;
    },
  },
  mounted() {
    this.loading.form = true;
    this.getAssetManagement(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.profileLevel =
            response.data.profile_level ?? levelTypes.MODERATE;
          this.rebalancingMetodology =
            response.data.management_rebalancing_metodology_id;
          this.orientations = response.data.orientations;
          this.investmentGoalsData.postFixed = response.data.post_fixed;
          this.investmentGoalsData.inflationRate = response.data.inflation_rate;
          this.investmentGoalsData.preFixed = response.data.pre_fixed;
          this.investmentGoalsData.multimarket = response.data.multimarket;
          this.investmentGoalsData.alternatives = response.data.alternatives;
          this.investmentGoalsData.international = response.data.international;
          this.investmentGoalsData.variableIncome =
            response.data.variable_income;
        }
      })
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.form = false;
      });
    this.loading.rebalancingMetodologies = true;
    this.getRebalancingMetodologies()
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as metodologias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.rebalancingMetodologies = false;
      });
  },
  methods: {
    ...mapActions({
      getRebalancingMetodologies: types.GET_ASSET_REBALANCING_METODOLOGIES,
      getAssetManagement: types.GET_ASSET_MANAGEMENT,
      saveAssetManagement: types.SAVE_ASSET_MANAGEMENT,
      openWalletInvestmentGoalsSidebar:
        types.OPEN_WALLET_INVESTMENT_GOALS_SIDEBAR,
      openInvestmentProfileSidebar: types.OPEN_INVESTMENT_PROFILE_SIDEBAR,
    }),
    saveData() {
      const { profileLevel, rebalancingMetodology, orientations } = this;
      const payload = {
        customer_id: this.$route.params.id,
        profile_level: profileLevel,
        management_rebalancing_metodology_id: rebalancingMetodology,
        orientations: orientations,
      };
      this.saveAssetManagement(payload).catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao salvar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    editInvestmentGoals() {
      this.openWalletInvestmentGoalsSidebar({
        saveAction: () => {
          const { investmentGoalsData } = this;
          this.saveAssetManagement({
            customer_id: this.$route.params.id,
            post_fixed: investmentGoalsData.postFixed,
            inflation_rate: investmentGoalsData.inflationRate,
            pre_fixed: investmentGoalsData.preFixed,
            multimarket: investmentGoalsData.multimarket,
            alternatives: investmentGoalsData.alternatives,
            international: investmentGoalsData.international,
            variable_income: investmentGoalsData.variableIncome,
          })
            .then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso!",
                  text: "Metas atualizadas com sucesso.",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao salvar a proteção. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        },
      });
    },
    editProfile() {
      this.openInvestmentProfileSidebar({
        saveAction: () => {
          const { profileLevel } = this;
          this.saveAssetManagement({
            customer_id: this.$route.params.id,
            profile_level: profileLevel
          })
            .then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso!",
                  text: "Perfil atualizado com sucesso.",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao atualizar o perfil. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        },
      });
    },
    setInvestmentProfile(profileLevel) {
      this.profileLevel = profileLevel;
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  height: 100px;
}
.asset-profile {
  border-right: 2px solid #5a698d;
  .investment-level {
    background-color: #dcdcdc;
    border-radius: 10px;
    height: 68px;
    display: flex;
    align-items: center;
    img {
      margin: 10px;
    }
    .level-name {
      display: grid;
    }
  }
}
.wallet-goals {
  .wallet-goal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dcdcdc;
    height: 40px;
    border-radius: 10px;
    .goal-title {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 10px;
      }
      .percentage {
        margin-right: 10px;
      }
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97px;
    height: 30px;
  }
}
.dark-layout .asset-profile .investment-level,
.dark-layout .wallet-goals .wallet-goal {
  background-color: #1e263a;
}
</style>
