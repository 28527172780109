<template>
  <div>
    <!-- Filters -->
    <b-card class="financial-planning-step-6">
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Prioridade e objetivos</h4>
            <span class="text-muted h5">Passo 06</span>
          </div>
        </div>
      </b-card-title>

      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-1">
            <strong>Objetivos em ordem de prioridade</strong>
          </b-col>
        </b-row>

        <template v-if="loading.table">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner big variant="primary" />
          </b-row>
        </template>

        <b-row v-else>
          <b-col cols="12" md="12" class="mb-4" style="overflow: overlay;">
            <table v-if="objectives && objectives.length" class="financial-planning-table">
              <thead style="background: none !important;">
                <tr style="background: none !important;">
                  <th width="30%">Objetivo</th>
                  <th width="30%">Valor</th>
                  <th width="15%">Data</th> 
                </tr>
              </thead>
              <tbody>
                <tr v-for="protection in objectives" :key="protection.id">
                  <td>
                    <div class="d-flex justify-content-start">
                      <div class="first-column-text">
                        {{ protection.title }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>
                      {{ protection.how_much_to_invest | toCurrency }}
                    </span>
                  </td>
                  <td>{{ formatDate(protection.when) }}</td>
                </tr>
              </tbody>
            </table>
            <span v-else> Não foram informados objetivos na entrevista </span>
          </b-col>
        </b-row>

        <div class="financial-previous-step">
          <b-button variant="outline-primary" @click="toStep('previous')">Voltar</b-button>
        </div>
        <div class="financial-next-step">
          <b-button variant="primary" @click="toStep('next')">Próximo</b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { toCurrency } from '@/helpers/converters';
import * as types from '../store/types';
import useAppConfig from '@core/app-config/useAppConfig';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BSpinner,
    FeatherIcon,
  },
  props: {
    toStep: Function,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: {
        table: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      objectives: types.OBJECTIVES,
    }),
  },
  mounted() {
    this.getObjectives();
  },
  methods: {
    ...mapActions({
      getCustomerObjectives: types.GET_OBJECTIVES
    }),
    getObjectives() {
      this.loading.table = true;
      this.getCustomerObjectives(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        })
        .finally(() => {
          console.log(this.objectives)
          this.loading.table = false;
        });
    },
    formatDate(date) {
      if (!date) return '';
      return new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(date));
    },
  },
};
</script>

<style lang="scss">
.financial-planning-step-6{
  .protection-needs{
    .custom-control-label{
      font-size: 12px !important;
    }
  }
  .boder-col{
    border-top: 1px solid #5A698D;
  }
  .add-new-protection{
    display: grid;
    justify-content: end;
    align-items: center;
    button{
      display: flex;
      align-items: center;
      border-radius: 10px;
      height: 30px;
    }
  }
}
</style>