<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Poupar eficiente</h4>
            <span class="text-muted h5">Passo 01</span>
          </div>
        </div>
      </b-card-title>
      <template v-if="loading">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner
            big 
            variant="primary"
          />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6">
            Capacidade de poupança do cliente
            <br>
            <small class="text-muted">
              Insira o valor que você consegue poupar mensalmente. 
              Este valor será usado para calcular o percentual de sua renda bruta 
              que pode ser economizado e ajustado conforme as metas
            </small> 
          </b-col>
          <b-col cols="12" md="6">
            <b-form-input
              v-money="moneyFormat"
              v-model="maxSavingAmount"
              :class="{ 'is-invalid': v$.maxSavingAmount.$error }"
              @blur="saveData"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.maxSavingAmount.required.$invalid 
                "
              >
                Você deve informar um valor válido
              </span>
            </div>
            <div v-if="percentageOfIncome" class="gross-value-info">
              <span class="pt-2">O valor corresponde a:</span> <br>
              <span class="gross-value-percentage">{{ percentageOfIncome }}% da renda bruta do cliente</span>
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col cols="12" md="6">
            Oportunidade de corte de gastos
            <br>
            <small class="text-muted">
              Preencha este campo com despesas que podem ser reduzidas ou eliminadas. 
              O sistema irá sugerir formas de otimizar seus gastos com base nas informações fornecidas.
            </small> 
          </b-col>
          <b-col cols="12" md="6">
            <b-form-textarea
              v-model="cutExpensesOportunities"
              @blur="saveData"
            />
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col cols="12" md="6">
            Oportunidade de aumento de receitas
            <br>
            <small class="text-muted">
              Preencha com as possíveis sugestões de aumento de receitas para o cliente, como novas fontes de renda ou estratégias de investimento. 
              Esse campo ajudará a projetar o impacto de um aumento de ganhos no planejamento financeiro.
            </small> 
          </b-col>
          <b-col cols="12" md="6">
            <b-form-textarea
              v-model="revenueOportunities"
              @blur="saveData"
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end align-items-end financial-next-step">
          <b-button
            variant="primary"
            @click="toStep('next')"
          > Próximo
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect
  },
  props: {
    toStep: Function
  },
  directives: {
    money: VMoney,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      maxSavingAmount: undefined,
      cutExpensesOportunities: undefined,
      revenueOportunities: undefined,
      cashFlowIncome: undefined
    };
  },
  validations() {
    return {
      maxSavingAmount: {required}
    }
  },
  computed: {
    moneyFormat: function () {
      return getBRLFormat();
    },
    percentageOfIncome: function () {
      const savingAmount = moneyToFloat(this.maxSavingAmount)
      if(this.cashFlowIncome && this.cashFlowIncome > 0 && savingAmount > 0){
        return ((savingAmount / this.cashFlowIncome) * 100).toFixed(0)
      }
      return 0
    }
  },
  mounted(){
    this.loading = true
    this.getEfficiency(this.$route.params.id)
      .then((response) => {
        if(response.data){
          this.maxSavingAmount = floatToMoney(response.data.max_saving_amount);
          this.cutExpensesOportunities = response.data.cut_expenses_oportunities;
          this.revenueOportunities = response.data.revenue_oportunities;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de eficiência. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false
      })
    this.getCashFlowIncome(this.$route.params.id)
      .then((response) => {
        if(response.data){
          this.cashFlowIncome = response.data.value
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de eficiência. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
  },
  methods: {
    ...mapActions({
      getEfficiency: types.GET_EFFICIENCY,
      saveEfficiency: types.SAVE_EFFICIENCY,
      getCashFlowIncome: types.GET_CASH_FLOW_INCOME
    }),
    saveData(){
      const { maxSavingAmount, cutExpensesOportunities, revenueOportunities } = this
      const payload = {
        max_saving_amount: moneyToFloat(maxSavingAmount),
        cut_expenses_oportunities: cutExpensesOportunities,
        revenue_oportunities: revenueOportunities,
        customer_id: this.$route.params.id
      }
      this.saveEfficiency(payload)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar as informações de eficiência. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.gross-value-info{
  margin-top: 10px;
  .gross-value-percentage{
    color: #FF9D00;
    font-weight: 600;
  }
}
textarea{
  height: 100px;
}
</style>

