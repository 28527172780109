<template>
  <div>
    <b-sidebar
      id="sidebar-risk-protection"
      sidebar-class="sidebar-lg"
      :visible="implementationsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateImplementationsSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="planning-sidebar-header px-2 py-1">
          <h4>Recomendação para o cliente</h4>
        </div>
        <template v-if="loading">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner big variant="primary" />
          </b-row>
        </template>
        <div class="m-2" v-else>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Recomendação" label-for="recommendation">
                <b-form-textarea
                  v-model="recommendation"
                  style="height: 136px"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.recommendation.required.$invalid">
                    Você deve informar o objetivo.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <label><b>Defina um prazo a ser concluído:</b></label>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12" md="6">
              <b-form-group label="Quantidade" label-for="deadline">
                <v-select
                  id="deadline"
                  v-model="deadline"
                  :reduce="(deadline) => deadline.id"
                  :options="deadlineOptions"
                  label="name"
                  :class="getSelectErrorClass(v$.deadline.$error)"
                >
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.deadline.required.$invalid">
                    Você deve selecionar um número de prazo.
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Escala de tempo" label-for="deadlineType">
                <v-select
                  id="deadlineType"
                  v-model="deadlineType"
                  :reduce="(deadlineType) => deadlineType.name"
                  :options="deadlineTypes"
                  label="name"
                  :class="getSelectErrorClass(v$.deadlineType.$error)"
                >
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.deadlineType.required.$invalid">
                    Você deve selecionar um prazo.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="planning-sidebar-bottom px-1">
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button variant="outline-primary" @click="hide">
              Fechar
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button variant="primary" @click="save"> Salvar </b-button>
          </b-col>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import vSelect from "vue-select";
import * as types from "../store/types";
import { getVueSelectErrorClass } from "@/helpers/validators";
import { DEADLINE_TYPES } from "@/constants/deadline_types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      loading: false,
      recommendation: undefined,
      deadline: undefined,
      deadlineType: undefined,
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  computed: {
    ...mapGetters({
      implementationsSidebar: types.IMPLEMENTATIONS_SIDEBAR,
    }),
    deadlineOptions: function () {
      var deadlineOptions = [];
      for (let i = 1; i <= 31; i++) {
        deadlineOptions.push({
          id: i,
          name: i,
        });
      }
      return deadlineOptions;
    },
    deadlineTypes: function () {
      return DEADLINE_TYPES;
    },
  },
  validations() {
    return {
      recommendation: { required },
      deadline: { required },
      deadlineType: { required },
    };
  },
  methods: {
    ...mapMutations({
      mutateImplementationsSidebar: types.MUTATE_IMPLEMENTATIONS_SIDEBAR,
    }),
    ...mapActions({
      getImplementation: types.GET_IMPLEMENTATION,
      saveImplementation: types.SAVE_IMPLEMENTATION,
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    onShow() {
      if (this.implementationsSidebar.id) {
        this.loading = true;
        this.getImplementation({
          id: this.implementationsSidebar.id,
          customer_id: this.$route.params.id,
        })
          .then((response) => {
            if (response && response.data) {
              this.recommendation = response.data.recommendation;
              this.deadline = response.data.deadline;
              this.deadlineType = response.data.deadline_type;
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao obter as informações da proteção. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const { recommendation, deadline, deadlineType } = this;
      this.saveImplementation({
        id: this.implementationsSidebar.id,
        customer_id: this.$route.params.id,
        recommendation,
        deadline,
        deadline_type: deadlineType,
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso!",
              text: "Implementação salva com sucesso.",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.implementationsSidebar.saveAction();
          this.mutateImplementationsSidebar({ visible: false });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar a implementação. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clear() {
      this.recommendation = undefined;
      this.deadline = undefined;
      this.deadlineType = undefined;
      this.mutateImplementationsSidebar({ id: undefined });
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
