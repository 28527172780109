<template>
  <div>
    <!-- Filters -->
    <b-card class="financial-planning-step-3">
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Reserva de curto prazo</h4>
            <span class="text-muted h5">Passo 05</span>
          </div>
        </div>
      </b-card-title>
      <template v-if="loading">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row class="mb-2">
          <b-col cols="12" md="6"> Reserva do cliente (em meses): </b-col>
          <b-col cols="12" md="6">
            <b-form-input
              placeholder="Informe a quantidade de meses. Ex.: 6"
              v-model="months"
              @blur="saveData"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            E o valor recomendado é de:
            <br />
          </b-col>
          <b-col cols="12" md="6">
            <b-form-input
              v-model="recommendedAmount"
              v-money="moneyFormat"
              :class="{ 'is-invalid': v$.recommendedAmount.$error }"
              @blur="saveData"
            />
            <div class="invalid-feedback">
              <span v-if="v$.recommendedAmount.required.$invalid">
                Você deve informar um valor válido
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col cols="12" md="6">
            Orientação para o cliente sobre a alocação e orientações gerais da
            reserva de emergência:
            <br />
          </b-col>
          <b-col cols="12" md="6">
            <b-form-textarea
              v-model="allocationOrientations"
              @blur="saveData"
            />
          </b-col>
        </b-row>
        <div class="financial-previous-step">
          <b-button variant="outline-primary" @click="toStep('previous')">
            Voltar
          </b-button>
        </div>
        <div class="financial-next-step">
          <b-button variant="primary" @click="toStep('next')">
            Próximo
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  props: {
    toStep: Function,
  },
  directives: {
    money: VMoney,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      months: undefined,
      recommendedAmount: undefined,
      allocationOrientations: undefined,
    };
  },
  validations() {
    return {
      recommendedAmount: { required },
    };
  },
  computed: {
    moneyFormat: function () {
      return getBRLFormat();
    },
  },
  mounted() {
    this.loading = true;
    this.getSavings(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.months = response.data.months;
          this.recommendedAmount = floatToMoney(
            response.data.recommended_amount
          );
          this.allocationOrientations = response.data.allocation_orientations;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      getSavings: types.GET_SAVINGS,
      saveSavings: types.SAVE_SAVINGS,
    }),
    addProtection() {},
    saveData() {
      const { months, recommendedAmount, allocationOrientations } = this;

      this.saveSavings({
        id: this.$route.params.id,
        months,
        recommended_amount: moneyToFloat(recommendedAmount),
        allocation_orientations: allocationOrientations,
        customer_id: this.$route.params.id,
      }).catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao salvar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
  },
};
</script>

<style lang="scss">
.financial-planning-step-3 {
  .protection-needs {
    .custom-control-label {
      font-size: 12px !important;
    }
  }
  .boder-col {
    border-top: 1px solid #5a698d;
  }
  .add-new-protection {
    display: grid;
    justify-content: end;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      border-radius: 10px;
      height: 30px;
    }
  }
}
</style>
