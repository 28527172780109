<template>
  <div>
    <risk-protection-sidebar />
    <!-- Filters -->
    <b-card class="financial-planning-step-3">
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Gestão de riscos</h4>
            <span class="text-muted h5">Passo 03</span>
          </div>
        </div>
      </b-card-title>
      <template v-if="loading.form">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row class="protection-needs">
          <b-col cols="12" class="mb-1">
            <span
              >Escolha entre as opções abaixo, quais seriam as necessidades de
              proteção do cliente?</span
            >
          </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-form-checkbox v-model="health_monitoring" @change="saveData">
              <span>Monitorar a saúde</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-form-checkbox v-model="health_recovery" @change="saveData">
              <span>Recuperar a saúde</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-form-checkbox
              v-model="professional_force_protection"
              @change="saveData"
            >
              <span>Proteger a força de trabalho</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-form-checkbox v-model="dependent_coverage" @change="saveData">
              <span>Garantir os dependentes</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-form-checkbox
              v-model="professional_liabilities"
              @change="saveData"
            >
              <span>Responsabilidade profissional</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-form-checkbox
              v-model="real_estate_protection"
              @change="saveData"
            >
              <span>Proteção de bens (imóvel)</span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-form-checkbox
              v-model="high_value_asset_protection"
              @change="saveData"
            >
              <span>Proteção de bens (alto valor)</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-2">
            <div class="boder-col"></div>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="6">
            <span>Renda passiva emergencial</span>
          </b-col>
          <b-col cols="12" md="6">
            <span>Novo valor de poupar</span>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="6">
            <b-form-group
              label="Somatória gastos mensais clientes"
              label-for="month-expenses"
              label-size="sm"
            >
              <b-form-input
                id="month-expenses"
                v-model="monthly_expenses_sum"
                v-money="moneyFormat"
                @blur="saveData"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="O custos com as novas proteções"
              label-for="protections-costs"
              label-size="sm"
            >
              <b-form-input
                id="protections-costs"
                v-model="cost_of_new_protections"
                v-money="moneyFormat"
                @blur="saveData"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group
              label="Indenização de morte necessária é:"
              label-for="death-indemnity"
              label-size="sm"
            >
              <b-form-input
                id="month-expenses"
                v-model="death_indemnity"
                v-money="moneyFormat"
                @blur="saveData"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="Nova capacidade de poupança do cliente:"
              label-for="savings-capability"
              label-size="sm"
            >
              <b-form-input
                id="savings-capability"
                v-model="new_client_savings_amount"
                v-money="moneyFormat"
                @blur="saveData"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-items-center">
          <b-col cols="12" class="mb-2 mt-2">
            <div class="boder-col"></div>
          </b-col>
          <b-col cols="12" md="6" class="mb-1">
            <span>Proteção detalhada</span>
          </b-col>
          <b-col cols="12" md="6" class="mb-1 add-new-protection">
            <b-button variant="success" @click="editProtection(undefined)">
              Adicionar
            </b-button>
          </b-col>
        </b-row>
        <template v-if="loading.table">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner big variant="primary" />
          </b-row>
        </template>
        <b-row v-else>
          <b-col cols="12" md="12" class="mb-4" style="overflow: overlay">
            <table class="financial-planning-table">
              <thead>
                <tr>
                  <th width="30%">Produto/necessidade de proteção</th>
                  <th width="30%">Seguradora/cobertura</th>
                  <th width="15%">Indenização</th>
                  <th width="15%">Prêmio</th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="protection in riskProtections">
                  <td>
                    <div class="d-flex justify-content-start">
                      <div class="first-column-icon">
                        <feather-icon
                          icon="ShieldIcon"
                          size="25"
                          style="position: absolute"
                        />
                        <img
                          v-if="skin == 'dark'"
                          :src="
                            require('@/assets/images/svg/icn_circle_planning_table-dark.svg')
                          "
                          width="40px"
                        />
                        <img
                          v-else
                          :src="
                            require('@/assets/images/svg/icn_circle_planning_table.svg')
                          "
                          width="40px"
                        />
                      </div>
                      <div class="first-column-text">
                        <b>{{ protection.product }}</b
                        ><br />
                        {{ protection.need }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <b>{{ protection.company }}</b
                    ><br />
                    <template v-for="(coverage, index) in protection.coverages">
                      {{
                        index < protection.coverages.length - 1
                          ? coverage.name + ","
                          : coverage.name
                      }}
                    </template>
                  </td>
                  <td>
                    {{ protection.compensation | toCurrency }}
                  </td>
                  <td>
                    {{ protection.award | toCurrency }}
                  </td>
                  <td>
                    <b-button
                      variant="link"
                      @click="editProtection(protection.id)"
                    >
                      <feather-icon icon="Edit3Icon" size="16" />
                    </b-button>
                    <b-button
                      variant="link"
                      @click="deleteProtection(protection.id)"
                    >
                      <feather-icon icon="Trash2Icon" size="16" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <div class="financial-previous-step">
          <b-button variant="outline-primary" @click="toStep('previous')">
            Voltar
          </b-button>
        </div>
        <div class="financial-next-step">
          <b-button variant="primary" @click="toStep('next')">
            Próximo
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import _ from "lodash";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import RiskProtectionSidebar from "../components/RiskProtectionSidebar";
import useVuelidate from "@vuelidate/core";
import { floatToMoney, moneyToFloat, toCurrency } from "@/helpers/converters";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    RiskProtectionSidebar,
  },
  props: {
    toStep: Function,
  },
  directives: {
    money: VMoney,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: {
        form: false,
        table: false,
      },
      health_monitoring: undefined,
      dependent_coverage: undefined,
      high_value_asset_protection: undefined,
      health_recovery: undefined,
      professional_liabilities: undefined,
      professional_force_protection: undefined,
      real_estate_protection: undefined,
      monthly_expenses_sum: undefined,
      death_indemnity: undefined,
      cost_of_new_protections: undefined,
      new_client_savings_amount: undefined,
    };
  },
  computed: {
    ...mapGetters({
      riskProtections: types.RISK_PROTECTIONS,
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
  },
  mounted() {
    this.loading.form = true;
    this.getRisks(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.health_monitoring = Boolean(response.data.health_monitoring);
          this.dependent_coverage = Boolean(response.data.dependent_coverage);
          this.high_value_asset_protection = Boolean(
            response.data.high_value_asset_protection
          );
          this.health_recovery = Boolean(response.data.health_recovery);
          this.professional_liabilities = Boolean(
            response.data.professional_liabilities
          );
          this.professional_force_protection = Boolean(
            response.data.professional_force_protection
          );
          this.real_estate_protection = Boolean(
            response.data.real_estate_protection
          );
          this.monthly_expenses_sum = floatToMoney(
            response.data.monthly_expenses_sum
          );
          this.death_indemnity = floatToMoney(response.data.death_indemnity);
          this.cost_of_new_protections = floatToMoney(
            response.data.cost_of_new_protections
          );
          this.new_client_savings_amount = floatToMoney(
            response.data.new_client_savings_amount
          );
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.form = false;
      });
    this.getProtections();
  },
  methods: {
    ...mapActions({
      getRisks: types.GET_RISKS,
      saveRisks: types.SAVE_RISKS,
      getRisksProtections: types.GET_RISKS_PROTECTIONS,
      removeRiskProtection: types.REMOVE_RISK_PROTECTION,
      openRiskProtectionSidebar: types.OPEN_RISK_PROTECTION_SIDEBAR,
    }),
    editProtection(id) {
      this.openRiskProtectionSidebar({
        id,
        saveAction: () => {
          this.getProtections();
        },
      });
    },
    saveData() {
      const {
        health_monitoring,
        dependent_coverage,
        high_value_asset_protection,
        health_recovery,
        professional_liabilities,
        professional_force_protection,
        real_estate_protection,
        monthly_expenses_sum,
        death_indemnity,
        cost_of_new_protections,
        new_client_savings_amount,
      } = this;

      this.saveRisks({
        id: this.$route.params.id,
        health_monitoring,
        dependent_coverage,
        high_value_asset_protection,
        health_recovery,
        professional_liabilities,
        professional_force_protection,
        real_estate_protection,
        monthly_expenses_sum: moneyToFloat(monthly_expenses_sum),
        death_indemnity: moneyToFloat(death_indemnity),
        cost_of_new_protections: moneyToFloat(cost_of_new_protections),
        new_client_savings_amount: moneyToFloat(new_client_savings_amount),
        customer_id: this.$route.params.id,
      }).catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao salvar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    getProtections() {
      this.loading.table = true;
      this.getRisksProtections(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    deleteProtection(id) {
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja remover a proteção?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.removeRiskProtection({
            id,
            customer_id: this.$route.params.id,
          })
            .then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso!",
                  text: "Proteção removida com sucesso.",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.getProtections();
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao remover a proteção. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.financial-planning-step-3 {
  .protection-needs {
    .custom-control-label {
      font-size: 12px !important;
    }
  }
  .boder-col {
    border-top: 1px solid #5a698d;
  }
  .add-new-protection {
    display: grid;
    justify-content: end;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      border-radius: 10px;
      height: 30px;
    }
  }
}
</style>
