<template>
  <div>
    <!-- Filters -->
    <template v-if="loading || !planningStatus">
      <b-row class="justify-content-md-center mt-2">
        <b-spinner big variant="primary" />
      </b-row>
    </template>
    <template v-else>
      <b-row class="financial-planning-menu">
        <b-col cols="12" md="3">
          <div class="mb-2 header">
            8 passos para a<br />
            <b>Independência Financeira</b><br />
            <small
              class="text-muted text-primary"
              v-if="customer && customer.name"
            >
              {{ customer.name }}
            </small>
          </div>
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 1"
            :number="1"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 1 || isFinished"
            title="Poupar eficiente"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 2"
            :number="2"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 2 || isFinished"
            title="Gestão financeira"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 3"
            :number="3"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 3 || isFinished"
            title="Gestão de riscos"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 4"
            :number="4"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 4 || isFinished"
            title="Garantia de renda futura"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 5"
            :number="5"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 5 || isFinished"
            title="Reserva de curto prazo"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 6"
            :number="6"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 6 || isFinished"
            title="Prioridade e objetivos"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 7"
            :number="7"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 7 || isFinished"
            title="Gestão de ativos"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 8"
            :number="8"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 8 || isFinished"
            title="Planejamento tributário e sucessório"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected === 9"
            :number="9"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="stepSelected > 9 || isFinished"
            title="Implementação"
            :click="selectStep"
          />
          <FinancialPlanningStepMenuItem
            :selected="stepSelected == 10"
            :number="10"
            :disabled="planningStatus.finished_at !== null"
            :stepDone="isFinished"
            title="Conferindo os dados do cliente"
            :click="selectStep"
          />
          <div class="progression">
            <div class="title">Progresso total: {{ stepsProgress }}%</div>
            <div class="step-progress">
              <div class="progress-container">
                <div class="bar" :style="{ width: stepsProgress + '%' }"></div>
              </div>
            </div>
          </div>
        </b-col>
        <template v-if="!isFinished">
          <b-col cols="12" md="9">
            <FinancialPlanningStep1
              v-if="stepSelected === 1"
              :toStep="toStep"
            />
            <FinancialPlanningStep2
              v-if="stepSelected === 2"
              :toStep="toStep"
            />
            <FinancialPlanningStep3
              v-if="stepSelected === 3"
              :toStep="toStep"
            />
            <FinancialPlanningStep4
              v-if="stepSelected === 4"
              :toStep="toStep"
            />
            <FinancialPlanningStep5
              v-if="stepSelected === 5"
              :toStep="toStep"
            />
            <FinancialPlanningStep6
              v-if="stepSelected === 6"
              :toStep="toStep"
            />
            <FinancialPlanningStep7
              v-if="stepSelected === 7"
              :toStep="toStep"
            />
            <FinancialPlanningStep8
              v-if="stepSelected === 8"
              :toStep="toStep"
            />
            <FinancialPlanningStep9
              v-if="stepSelected === 9"
              :toStep="toStep"
            />
            <FinancialPlanningStep10
              v-if="stepSelected === 10"
              :toStep="toStep"
            />
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12" md="9">
            <b-card class="step-card-finished">
              <div class="d-flex justify-content-between align-items-center">
                <div class="financial-step-title m-1">
                  <span class="ml-1">Plano financeiro concluído!</span>
                </div>
              </div>
              <b-card-body>
                <b-row>
                  <b-col cols="12">
                    <label
                      >Compartilhe o link com seu cliente, onde ele poderá confirmar o recebimento: </label
                    ><br />
                    <v-row>
                      <button
                        class="btn btn-outline-light btn-final-link mt-2"
                        @click.prevent="
                          copyLinkToClipboard(planningLinkUrlForCustomers)
                        "
                      >
                        Copiar link do plano financeiro para o cliente
                      </button>
                      <button
                        class="btn btn-whatsapp btn-final-link mt-2"
                        @click.prevent="openWhatsappLink"
                      >
                        <img
                          src="~@/assets/images/icons/ic-whatsapp-green.svg"
                          alt="Compartilhar link por whatsapp"
                          class="mr-1"
                        />
                        Compartilhar plano financeiro por whatsapp
                      </button>
                    </v-row>
                  </b-col>
                  <b-col cols="12" class="mt-3">
                    <label>Quer ver como ficou? Visualize agora! </label
                    ><br />
                    <button
                      class="btn btn-outline-light btn-final-link mt-2"
                      @click.prevent="openLink(planningLinkUrlForPns)"
                    >
                      Visualizar plano financeiro
                    </button>
                  </b-col>
                  <b-col cols="12" class="d-flex justify-content-end">
                    <b-button
                      class="edit-button"
                      variant="primary"
                      @click="edit"
                    >
                      Editar plano
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </template>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import FinancialPlanningStepMenuItem from "../components/FinancialPlanningStepMenuItem.vue";
import FinancialPlanningStep1 from "./FinancialPlanningStep1.vue";
import FinancialPlanningStep2 from "./FinancialPlanningStep2.vue";
import FinancialPlanningStep3 from "./FinancialPlanningStep3.vue";
import FinancialPlanningStep4 from "./FinancialPlanningStep4.vue";
import FinancialPlanningStep5 from "./FinancialPlanningStep5.vue";
import FinancialPlanningStep6 from "./FinancialPlanningStep6.vue";
import FinancialPlanningStep7 from "./FinancialPlanningStep7.vue";
import FinancialPlanningStep8 from "./FinancialPlanningStep8.vue";
import FinancialPlanningStep9 from "./FinancialPlanningStep9.vue";
import FinancialPlanningStep10 from "./FinancialPlanningStep10.vue";

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    FinancialPlanningStepMenuItem,
    FinancialPlanningStep1,
    FinancialPlanningStep2,
    FinancialPlanningStep3,
    FinancialPlanningStep4,
    FinancialPlanningStep5,
    FinancialPlanningStep6,
    FinancialPlanningStep7,
    FinancialPlanningStep8,
    FinancialPlanningStep9,
    FinancialPlanningStep10,
  },
  data() {
    return {
      loading: false,
      stepSelected: 1,
      customer: undefined,
      financialDiagnosisUrl:
        process.env.VUE_APP_FINANCIAL_DIAGNOSIS_FRONTEND_URL,
    };
  },
  setup() {
    return { toast: useToast() };
  },
  computed: {
    ...mapGetters({
      planningStatus: types.PLANNING_STATUS,
    }),
    stepsProgress: function () {
      if (this.isFinished) {
        return 100;
      }
      return ((this.stepSelected - 1) / 10) * 100;
    },
    isFinished: function () {
      return this.planningStatus.finished_at !== null;
    },
    planningLinkUrlForCustomers: function () {
      return (
        this.financialDiagnosisUrl +
        "/autenticacao-plano/" +
        this.planningStatus.token +
        "/cliente"
      );
    },
    planningLinkUrlForPns: function () {
      return (
        this.financialDiagnosisUrl +
        "/autenticacao-plano/" +
        this.planningStatus.token +
        "/pn"
      );
    },
  },
  watch: {
    stepSelected: function (val) {
      this.updatePlanningStatusCurrentStep({
        id: this.$route.params.id,
        step: val,
      });
    },
  },
  mounted() {
    this.loading = true;
    this.getStatus();
    this.getCustomer(this.$route.params.id)
      .then((response) => {
        if (response && response.data) {
          this.customer = response.data;
          this.loading = false;
        } else {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as informações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    ...mapActions({
      getCustomer: sharedTypes.GET_CUSTOMER,
      getPlanningStatus: types.GET_PLANNING_STATUS,
      updatePlanningStatusCurrentStep:
        types.UPDATE_PLANNING_STATUS_CURRENT_STEP,
      editPlanning: types.EDIT_PLANNING,
    }),
    selectStep(step) {
      this.stepSelected = step;
    },
    toStep(step) {
      if (typeof step === "number") {
        this.stepSelected = step;
      } else if (step == "previous") {
        this.stepSelected--;
      } else if (step == "next") {
        this.stepSelected++;
      }
    },
    getStatus() {
      this.getPlanningStatus(this.$route.params.id)
        .then(() => {
          this.stepSelected = this.planningStatus.current_step;
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as informações do plano. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    edit() {
      this.stepSelected = 1;
      this.editPlanning(this.$route.params.id).catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao editar o plano. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    openWhatsappLink() {
      const { name, cell_phone } = this.customer;
      const firstName = name.split(" ")[0];
      const message = `${firstName}, você poderá conferir o seu plano financeiro através do link: ${this.planningLinkUrlForCustomers}`;
      setTimeout(() => {
        window.open(
          `https://api.whatsapp.com/send?phone=${cell_phone}&text=${message}`,
          "_blank"
        ).focus();
      });
    },
    copyLinkToClipboard(link) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(link);
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Sucesso",
          text: "O link foi copiado para a área de transferência!",
          icon: "CoffeeIcon",
          variant: "success",
        },
      });
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "~@core/scss/base/components/include"; // Components includes

.financial-planning-menu {
  .card {
    border-radius: 15px !important;
    min-height: 650px;
  }
  .header {
    font-size: 14px;
  }
  .progression {
    .title {
      font-weight: 600;
      margin-top: 20px;
    }
    .step-progress {
      display: flex;
      .progress-container {
        width: 80%;
        background-color: #293045;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        margin: 20px 0;
        height: 9px;
        .bar {
          height: 100%;
          background-color: #02f518;
          color: #293045;
          border-radius: 10px;
          transition: width 0.5s ease;
        }
      }
    }
  }
  .financial-step-title {
    border-left: 3px solid #e54f01; /* Border on the left */
    border-top-left-radius: 2px; /* Rounded corner at top-left */
    border-bottom-left-radius: 2px;
    border-radius: 2px;
  }

  .financial-next-step {
    position: absolute;
    right: 30px;
    bottom: 30px;
    button {
      display: flex;
      border-radius: 10px;
      height: 32px;
      align-items: center;
      justify-content: center;
      width: 121px;
      font-size: 14px;
    }
  }
  .financial-previous-step {
    position: absolute;
    left: 30px;
    bottom: 30px;
    button {
      display: flex;
      border-radius: 10px;
      height: 32px;
      align-items: center;
      justify-content: center;
      width: 121px;
      font-size: 14px;
    }
  }
  .planning-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: black solid 1px;
  }

  .planning-sidebar-bottom {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: black solid 1px;
    .planing-sidebar-button {
      button {
        display: flex;
        border-radius: 10px;
        height: 38px;
        align-items: center;
        justify-content: center;
        width: 170px;
        font-size: 14px;
      }
    }
  }
  .financial-planning-table {
    width: 100%;
    padding-bottom: 2rem;
    border-collapse: separate;
    border-spacing: 0 10px;
    thead {
      background-color: #dcdcdc;
      font-size: 11px;
      font-weight: 600;
      th {
        padding: 6px 20px;
      }
      th:first-child {
        border-radius: 13px 0 0 13px;
      }
      th:last-child {
        border-radius: 0 13px 13px 0;
      }
    }
    tbody {
      tr {
        background-color: #dcdcdc;
      }
      td {
        padding: 6px 20px;
        font-size: 13px;
        .first-column-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            margin-left: 1px;
          }
        }
        .first-column-text {
          margin: 10px;
        }
      }
      td:first-child {
        border-radius: 13px 0 0 13px;
      }
      td:last-child {
        border-radius: 0 13px 13px 0;
      }
      button {
        padding: 0 5px !important;
        color: $body-color;
      }
    }
  }
}

.dark-layout .financial-planning-table tr,
.dark-layout .financial-planning-table thead {
  background-color: #343d55 !important;
}

.dark-layout .financial-planning-table button {
  color: $theme-dark-border-color;
}

.step-card-finished {
  min-height: 125px !important;
  border-radius: 15px;
  .financial-step-title {
    height: 20px !important;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  button {
    margin-top: 2rem;
    border-radius: 10px;
    width: 200px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.btn-final-link {
  min-width: 450px;
}
.btn-outline-light {
  color: #f26237;
  border-color: #f26237;
}
.btn-whatsapp {
  color: white;
  background: #25d366;
}
.btn-whatsapp:hover {
  color: white;
  background: #25d366;
}
</style>
