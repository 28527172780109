<template>
  <div>
    <b-sidebar
      id="sidebar-risk-protection"
      sidebar-class="sidebar-lg"
      :visible="riskProtectionSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateRiskProtectionSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="planning-sidebar-header px-2 py-1"
        >
          <h4>Adicionar nova proteção</h4>
        </div>
        <template v-if="loading.form">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner
              big 
              variant="primary"
            />
          </b-row>
        </template>
        <div class="m-2" v-else >
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Tipo de produto" label-for="protection-product">
                <v-select
                  id="protection-product"
                  v-model="product"
                  :reduce="(product) => product.id"
                  :options="products"
                  :loading="loading.products"
                  label="name"
                  :class="getSelectErrorClass(v$.product.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.product.required.$invalid">
                    Você deve selecionar um tipo de produto
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Qual a necessidade de proteção?" label-for="protection-need">
                <v-select
                  id="protection-need"
                  v-model="need"
                  :reduce="(need) => need.id"
                  :options="needs"
                  :loading="loading.needs"
                  label="name"
                  :class="getSelectErrorClass(v$.need.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.need.required.$invalid">
                    Você deve selecionar uma necessidade
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Selecione uma ou mais coberturas" label-for="protection-coverages">
                <v-select
                  id="protection-coverages"
                  v-model="coverage"
                  multiple
                  :reduce="(coverage) => coverage.id"
                  :options="coverages"
                  :loading="loading.coverages"
                  label="name"
                  :class="getSelectErrorClass(v$.coverage.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.coverage.required.$invalid">
                    Você deve selecionar uma ou mais coberturas
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Seguradora" label-for="protection-company">
                <v-select
                  id="protection-company"
                  v-model="company"
                  :reduce="(company) => company.id"
                  :options="companies"
                  :loading="loading.companies"
                  label="name"
                  :class="getSelectErrorClass(v$.company.$error)"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
                <div class="invalid-feedback">
                  <span v-if="v$.company.required.$invalid">
                    Você deve selecionar uma seguradora
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group label="Indenização" label-for="risk-compesation">
                <b-form-input
                  id="amount"
                  v-model="compensation"
                  v-money="moneyFormat"
                  :class="{ 'is-invalid': v$.compensation.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.compensation.required.$invalid">
                    Você deve informar um valor de indenização válido
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group label="Prêmio" label-for="risk-award">
                <b-form-input
                  id="amount"
                  v-model="award"
                  v-money="moneyFormat"
                  :class="{ 'is-invalid': v$.award.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.award.required.$invalid">
                    Você deve informar um valor de prémio válido
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="planning-sidebar-bottom px-1">
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button
              variant="outline-primary"
              @click="hide"
              > Fechar
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button
              variant="primary"
              @click="save"
            > Salvar
            </b-button>
          </b-col>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import vSelect from "vue-select";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from "../store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    vSelect
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      saving: false,
      loading:{
        form: false,
        products: false,
        companies: false,
        coverages: false,
        needs: false
      },
      product: undefined,
      need: undefined,
      coverage: [],
      company: undefined,
      compensation: undefined,
      award: undefined
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  computed: {
    ...mapGetters({
      riskProtectionSidebar: types.RISK_PROTECTION_SIDEBAR,
      products: types.RISK_PROTECTION_PRODUCTS,
      companies: types.RISK_PROTECTION_COMPANIES,
      coverages: types.RISK_PROTECTION_COVERAGES,
      needs: types.RISK_PROTECTION_NEEDS
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
  },
  validations() {
    return {
      product: { required },
      need: { required },
      coverage: { required },
      company: { required },
      compensation: { required },
      award: { required }
    };
  },
  mounted() {
    this.getProducts()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao obter os produtos para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.getCompanies()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao obter as empresas para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.getCoverages()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao obter as coberturas para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.getNeeds()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao obter as necessidades para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    ...mapMutations({
      mutateRiskProtectionSidebar: types.MUTATE_RISK_PROTECTION_SIDEBAR,
    }),
    ...mapActions({
      getProducts: types.GET_RISK_PROTECTION_PRODUCTS,
      getCompanies: types.GET_RISK_PROTECTION_COMPANIES,
      getCoverages: types.GET_RISK_PROTECTION_COVERAGES,
      getNeeds: types.GET_RISK_PROTECTION_NEEDS,
      getRiskProtection: types.GET_RISK_PROTECTION,
      saveRiskProtection: types.SAVE_RISK_PROTECTION
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    onShow() {
      if(this.riskProtectionSidebar.id){
        this.loading.form = true;
        this.getRiskProtection({
          id: this.riskProtectionSidebar.id,
          customer_id: this.$route.params.id
        })
          .then((response) => {
            if (response && response.data){
              this.product = response.data.protection_product_id;
              this.need = response.data.protection_need_id;
              this.coverage = JSON.parse(response.data.coverages);
              this.company = response.data.protection_company_id;
              this.compensation = floatToMoney(response.data.compensation);
              this.award = floatToMoney(response.data.award);
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Oops!',
                text: 'Ocorreu um erro ao obter as informações da proteção. Entre em contato com o setor de TI.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.loading.form = false;
          })
      }
    },
    async save(){
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const {
        product,
        need,
        coverage,
        company,
        compensation,
        award
      } = this 
      this.saveRiskProtection({
        id: this.riskProtectionSidebar.id,
        customer_id: this.$route.params.id,
        product,
        need,
        coverage,
        company,
        compensation: moneyToFloat(compensation),
        award: moneyToFloat(award)
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso!',
              text: 'Proteção salva com sucesso.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.riskProtectionSidebar.saveAction();
          this.mutateRiskProtectionSidebar({ visible: false });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao salvar a proteção. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.saving = false;
        })
    },
    clear() {
      this.product = undefined;
      this.need = undefined;
      this.coverage = [];
      this.company = undefined;
      this.compensation = undefined;
      this.award = undefined;
      this.mutateRiskProtectionSidebar({ id: undefined });
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
