<template>
  <div>
    <implementations-sidebar/>
    <!-- Filters -->
    <b-card>
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Implementação</h4>
            <span class="text-muted h5">Passo 09</span>
          </div>
        </div>
      </b-card-title>
      <b-card-body>
        <template v-if="loading">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner
              big 
              variant="primary"
            />
          </b-row>
        </template>
        <b-row v-else class="financial-planning-implementation">
          <b-col cols="12" class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <h5 class="mb-0">Recomendações</h5>
              <b-badge 
                variant="danger" 
                class="ml-1 d-flex align-items-center"
                pill
                style="height: 20px;"
              >{{ counter }}</b-badge>
            </div>
            <b-button
              variant="success"
              @click="editImplementation(undefined)"
            > 
              <feather-icon icon="PlusCircleIcon" size="16" style="margin-right: 10px;"/>  
              Adicionar
            </b-button>
          </b-col>
          <b-col cols="12" md="12" class="mb-4 mt-1" style="overflow: overlay;">
            <table v-if="implementations && implementations.length" class="financial-planning-table">
              <thead style="background: none !important">
                <tr style="background: none !important">
                  <th width="60%">
                    Recomendação
                  </th>
                  <th width="30%">
                    Prazo
                  </th>
                  <th width="10%">
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="implementation in implementations">
                  <td>
                    {{ implementation.recommendation }}
                  </td>
                  <td>
                    {{ implementation.deadline }} {{ implementation.deadline_type ? implementation.deadline_type.toLowerCase() : '' }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <b-button 
                        variant="link"
                        @click="editImplementation(implementation.id)"
                      >
                        <feather-icon icon="Edit3Icon" size="16"/>
                      </b-button>
                      <b-button 
                        variant="link"
                        @click="deleteImplementation(implementation.id)"
                      >
                        <feather-icon icon="Trash2Icon" size="16"/>
                      </b-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-else> Não foram encontradas recomendações para este cliente </span>
          </b-col>
        </b-row>
        <div class="financial-previous-step">
          <b-button
            variant="outline-primary"
            @click="toStep('previous')"
          > Voltar
          </b-button>
        </div>
        <div class="financial-next-step">
          <b-button
            variant="primary"
            @click="toStep('next')"
          > Próximo
          </b-button>
        </div>
      </b-card-body>
      
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import ImplementationsSidebar from "../components/ImplementationsSidebar";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    ImplementationsSidebar
  },
  props: {
    toStep: Function
  },
  directives: {
    money: VMoney,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      implementations: types.IMPLEMENTATIONS,
    }),
    counter: function() {
      return this.implementations.length;
    },
  },
  mounted(){
    this.loadImplementations();
  },
  methods: {
    ...mapActions({
      openImplementationsSidebar: types.OPEN_IMPLEMENTATIONS_SIDEBAR,
      getImplementations: types.GET_IMPLEMENTATIONS,
      removeImplementation: types.REMOVE_IMPLEMENTATION,
    }),
    loadImplementations(){
      this.loading = true;
      this.getImplementations(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao obter as implementações. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false;
        })
    },
    editImplementation(id){
      this.openImplementationsSidebar({
        id,
        saveAction: this.loadImplementations
      });
    },
    deleteImplementation(id){
      this.$swal({
        title: "Tem certeza?",
        text: `Deseja remover a implementação?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.removeImplementation({
            id,
            customer_id: this.$route.params.id,
          })
            .then(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso!",
                  text: "Implementação removida com sucesso.",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.loadImplementations()
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: "Ocorreu um erro ao remover a proteção. Entre em contato com o setor de TI.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.financial-planning-implementation{
  td{
    max-width: 40vw;
  }
  button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    height: 30px;
  }
}
</style>