<template>
  <div>
    <b-sidebar
      id="investment-profile"
      sidebar-class="sidebar-lg"
      :visible="investmentProfileSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateInvestmentProfileSidebar({ visible: val })"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="planning-sidebar-header px-2 py-1"
        >
          <h4>Selecione o perfil do investido</h4>
        </div>
        <div class="m-2">
          <b-row>
            <b-col cols="12" class="mb-2">
              <span>Escolha o perfil de investimento que melhor corresponde às suas expectativas e tolerância ao risco</span>
            </b-col>
            <b-col cols="12" class="mb-2">
              <div 
                class="profile-option"
                :class="{ 'selected': investmentProfile === 'conservative' }"
                @click="setInvestmentProfile('conservative')"
              >
                <div class="m-1">
                  <div class="option-title">
                    <img :src="require(`@/assets/images/financial_planning/profile_conservative.png`)" width="30px" >
                    <span class="option-name">Conservador</span>
                  </div>
                  <div class="option-description">
                    <small>
                      <span class="text-muted">Tolerância ao risco: </span>
                      <b>Baixa</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Objetivos: </span>
                      <b>Busca crescimento de capital a longo prazo</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Estratégia: </span>
                      <b>Prefere ativos de baixo risco para altos retornos</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Horizonte de Investimento: </span>
                      <b>Mais de 10 anos</b>
                    </small>
                  </div>
                </div>
              </div>
              <div 
                class="profile-option mt-1"
                :class="{ 'selected': investmentProfile === 'moderate' }"
                @click="setInvestmentProfile('moderate')"
              >
                <div class="m-1">
                  <div class="option-title">
                    <img :src="require(`@/assets/images/financial_planning/profile_moderate.png`)" width="30px" >
                    <span class="option-name">Moderado</span>
                  </div>
                  <div class="option-description">
                    <small>
                      <span class="text-muted">Tolerância ao risco: </span>
                      <b>Média</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Objetivos: </span>
                      <b>Equilíbrio entre crescimento e segurança</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Estratégia: </span>
                      <b>Ações e títulos para crescimento estável</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Horizonte de Investimento: </span>
                      <b>5 a 10 anos</b>
                    </small>
                  </div>
                </div>
              </div>
              <div 
                class="profile-option mt-1"
                :class="{ 'selected': investmentProfile === 'aggressive' }"
                @click="setInvestmentProfile('aggressive')"
              >
                <div class="m-1">
                  <div class="option-title">
                    <img :src="require(`@/assets/images/financial_planning/profile_aggressive.png`)" width="30px" >
                    <span class="option-name">Agressivo</span>
                  </div>
                  <div class="option-description">
                    <small>
                      <span class="text-muted">Tolerância ao risco: </span>
                      <b>Alta</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Objetivos: </span>
                      <b>Maximização dos retornos</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Estratégia: </span>
                      <b>Investe em ativos muito voláteis</b>
                    </small><br>
                    <small>
                      <span class="text-muted">Horizonte de Investimento: </span>
                      <b>Curto Prazo</b>
                    </small>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="planning-sidebar-bottom px-1">
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button
              variant="outline-primary"
              @click="hide"
              > Fechar
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button
              variant="primary"
              @click="save"
            > Salvar
            </b-button>
          </b-col>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import VueSlider from "vue-slider-component";
import * as types from "../store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    VueSlider
  },
  directives: {
    Ripple
  },
  data() {
    return {
    };
  },
  props: {
    investmentProfile: String,
    setInvestmentProfile: Function
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  computed: {
    ...mapGetters({
      investmentProfileSidebar: types.INVESTMENT_PROFILE_SIDEBAR
    })
  },
  methods: {
    ...mapMutations({
      mutateInvestmentProfileSidebar: types.MUTATE_INVESTMENT_PROFILE_SIDEBAR,
    }),
    save(){
      this.investmentProfileSidebar.saveAction()
      this.mutateInvestmentProfileSidebar({ visible: false })
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-option{
  height: 160px;
  border: 1px solid #5A698D;
  border-radius: 15px; 
  cursor: pointer;
  &.selected{
    background-color: #FF610112;
    border: 2px solid #FF6200;
  }
  .option-title{
    .option-name{
      margin-left: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .option-description{
    margin-left: 40px;
    font-size: 14px;
  }
}

.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>

