<template>
  <div v-if="loading.planning">
    <b-row class="justify-content-md-center mt-2">
      <b-spinner big variant="primary" />
    </b-row>
  </div>
  <div v-else>
    <!-- Filters -->
    <div class="financial-step-title m-1">
      <div class="m-1">
        <h4>Resumo para conferência</h4>
        <span class="text-muted h5">Passo 10</span>
      </div>
    </div>
    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.efficiency.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 1</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(1)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.efficiency">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6">
            <span>Capacidade de poupança do cliente:</span><br />
            <label v-if="efficiency.max_saving_amount">{{
              efficiency.max_saving_amount | toCurrency
            }}</label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col v-if="percentageOfIncome" cols="12" md="6">
            <span>O valor corresponde a:</span><br />
            <label>{{ percentageOfIncome }}% da renda bruta do cliente</label>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" md="6">
            <span>Oportunidades de corte de gastos:</span><br />
            <label v-if="efficiency.cut_expenses_oportunities">
              {{ efficiency.cut_expenses_oportunities }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6">
            <span>Oportunidade de aumento de receitas:</span><br />
            <label v-if="efficiency.revenue_oportunities">
              {{ efficiency.revenue_oportunities }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.financialManagement.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 2</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(2)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.financialManagement">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="4">
            <span>Cliente faz Fluxo de Caixa Corretamente?</span><br />
            <label>
              {{ financialManagement.using_customer_cash_flow ? "Sim" : "Não" }}
            </label>
          </b-col>
          <b-col cols="12" md="4">
            <span>Orientações para o cliente:</span><br />
            <label v-if="financialManagement.orientations">
              {{ financialManagement.orientations }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="4">
            <span>Idade de aposentadoria</span><br />
            <label v-if="financialManagement.retirement_age">
              {{ financialManagement.retirement_age }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.risks.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 3</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(3)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.risks">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12">
            <span
              >Escolha entre as opções abaixo, quais seriam as necessidades de
              proteção do cliente?</span
            ><br />
            <div class="d-flex align-items-center">
              <template v-for="(protection, index) in protectionNeeds">
                <span class="dot"></span
                ><label class="mr-1">{{ protection.label }}</label>
              </template>
              <label v-if="protectionNeeds.length === 0" class="text-danger"
                >Não informado</label
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <h5>Renda Passiva Emergencial</h5>
          </b-col>
          <b-col cols="12" md="4" class="mt-1">
            <span>Somatória gastos mensais clientes:</span><br />
            <label v-if="risks.monthly_expenses_sum">
              {{ risks.monthly_expenses_sum | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="4" class="mt-1">
            <span>O custos com as novas proteções:</span><br />
            <label v-if="risks.cost_of_new_protections">
              {{ risks.cost_of_new_protections | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="4" class="mt-1">
            <span>Indenização de morte necessária é:</span><br />
            <label v-if="risks.death_indemnity">
              {{ risks.death_indemnity | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="4" class="mt-1">
            <span>Nova capacidade de poupança do cliente:</span><br />
            <label v-if="risks.new_client_savings_amount">
              {{ risks.new_client_savings_amount | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <div class="d-flex">
              <h5>Proteção detalhada</h5>
              <b-badge
                variant="danger"
                class="ml-1 d-flex align-items-center"
                pill
                style="height: 20px"
                >{{ risksProtections.length }}</b-badge
              >
            </div>
          </b-col>
          <template v-for="(protection, index) in risksProtections">
            <b-col cols="12" md="4" class="mt-1">
              <div class="d-flex align-items-center justify-content-start">
                <div class="d-flex align-items-center justify-content-center">
                  <feather-icon
                    icon="ShieldIcon"
                    size="25"
                    style="position: absolute"
                  />
                  <img
                    v-if="skin == 'dark'"
                    :src="
                      require('@/assets/images/svg/icn_circle_planning_table-dark.svg')
                    "
                    width="40px"
                  />
                  <img
                    v-else
                    :src="
                      require('@/assets/images/svg/icn_circle_planning_table.svg')
                    "
                    width="40px"
                  />
                </div>
                <div class="ml-1">
                  <label>{{ protection.company }}</label>
                  <span>{{ protection.product }}</span>
                </div>
              </div>
            </b-col>
          </template>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.futureEarnings.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 4</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(4)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.futureEarnings">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6">
            <span>Montante da reserva necessária para a aposentadoria:</span
            ><br />
            <label v-if="futureEarnings.retirement_savings_amount">
              {{ futureEarnings.retirement_savings_amount | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6">
            <span>Expectativa da reserva futura do cliente:</span><br />
            <label v-if="futureEarnings.future_savings_amount">
              {{ futureEarnings.future_savings_amount | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" md="6">
            <span
              >Orientação para o cliente em relação à expectativa de renda na
              idade de aposentadoria desejada:</span
            ><br />
            <label v-if="futureEarnings.retirement_income_advice">
              {{ futureEarnings.retirement_income_advice }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6">
            <span
              >Orientação do planejador em relação a investir em previdência
              privada:</span
            ><br />
            <label v-if="futureEarnings.pension_investment_advice">
              {{ futureEarnings.pension_investment_advice }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.savings.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 5</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(5)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.savings">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6">
            <span>A reserva do cliente é referente à (meses):</span><br />
            <label v-if="savings.months"> {{ savings.months }} Meses </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6">
            <span>E o valor recomendado é de:</span><br />
            <label v-if="savings.recommended_amount">
              {{ savings.recommended_amount | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" md="6">
            <span
              >Orientação para o cliente sobre a alocação e orientações gerais
              da reserva de emergência:</span
            ><br />
            <label v-if="savings.allocation_orientations">
              {{ savings.allocation_orientations }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card class="step-card-validation">
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 6</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(6)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-1">
            <span>Objetivos registrados</span>
          </b-col>
        </b-row>

        <template v-if="loading.objectives">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner big variant="primary" />
          </b-row>
        </template>

        <b-row v-else>
          <b-col cols="12" md="12" class="mb-4" style="overflow: overlay">
            <table
              v-if="objectives && objectives.length"
              class="financial-planning-table"
            >
              <thead style="background: none !important">
                <tr style="background: none !important">
                  <th width="30%">Objetivo</th>
                  <th width="30%">Valor</th>
                  <th width="15%">Data</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="protection in objectives" :key="protection.id">
                  <td>
                    <div class="d-flex justify-content-start">
                      <div class="first-column-text">
                        {{ protection.title }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>
                      {{ protection.how_much_to_invest | toCurrency }}
                    </span>
                  </td>
                  <td>{{ formatDate(protection.when) }}</td>
                </tr>
              </tbody>
            </table>
            <span v-else>Não foram informados objetivos na entrevista</span>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.assetManagement.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 7</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(7)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.assetManagement">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6">
            <div class="mt-1">
              <span>Perfil do investidor:</span><br />
              <label>{{ profileLevelName }}</label>
            </div>
            <div class="mt-1">
              <span>Metodologia de rebalanceamento:</span><br />
              <label v-if="assetManagement.rebalancing_metodology">
                {{ assetManagement.rebalancing_metodology.name }}
              </label>
              <label v-else class="text-danger">Não informado</label>
            </div>
            <div class="mt-1">
              <span>Orientação para o cliente:</span><br />
              <label v-if="assetManagement.orientations">
                {{ assetManagement.orientations }}
              </label>
              <label v-else class="text-danger">Não informado</label>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="mt-1">
              <span>Metas de carteira:</span>
              <label v-if="v$.assetManagement.investment_goals.$invalid">
                <span class="text-danger">
                  É necessário informar uma distribuição para a carteira
                </span>
              </label>
              <template v-for="(goal, index) in investmentGoals">
                <div class="wallet-goal">
                  <div class="goal-title">
                    <div
                      class="dot"
                      :style="`background-color: ${goal.color};`"
                    ></div>
                    <span>{{ goal.title }}</span>
                  </div>
                  <span class="mr-1">{{ goal.value }}%</span>
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.taxes.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Passo 8</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(8)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.taxes">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6" class="mt-1">
            <span
              >Qual deve ser o tipo de declaração de imposto de renda do seu
              cliente?</span
            ><br />
            <label v-if="taxes.income_tax_type">
              {{ taxes.income_tax_type }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6" class="mt-1">
            <span>Existe a possibilidade para PGBL</span><br />
            <label v-if="taxes.pgbl_possibility != null">
              {{ taxes.pgbl_possibility ? "Sim" : "Não" }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
        <b-row v-if="taxes.pgbl_possibility == 1">
          <b-col cols="12" md="6" class="mt-1">
            <span>Valor anual de PGBL:</span><br />
            <label v-if="taxes.pgbl_anual">
              {{ taxes.pgbl_anual | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6" class="mt-1">
            <span>Economia anual com PGBL:</span><br />
            <label v-if="taxes.pgbl_economy">
              {{ taxes.pgbl_economy | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="mt-1">
            <span
              >Estado civil do seu cliente. Se casado ou União qual o regime de
              casamento?</span
            ><br />
            <label v-if="taxes.marital_status">
              {{ maritalStatus }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6" class="mt-1">
            <span>Valor do patrimônio do cliente em caso de sucessão:</span
            ><br />
            <label v-if="taxes.inheritance_successed">
              {{ taxes.inheritance_successed | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="mt-1">
            <span>Estimativa de custo de Inventário:</span><br />
            <label v-if="taxes.inventory_percentage">
              {{ taxes.inventory_percentage }}%
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
          <b-col cols="12" md="6" class="mt-1">
            <span>Correspondente à:</span><br />
            <label v-if="taxes.inventory_amount">
              {{ taxes.inventory_amount | toCurrency }}
            </label>
            <label v-else class="text-danger">Não informado</label>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      class="step-card-validation"
      :class="{ 'step-error': v$.implementations.$error }"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Implementação</span>
        </div>
        <div>
          <b-button variant="link" @click="toStep(9)">
            <FeatherIcon icon="EditIcon" size="16" />
          </b-button>
        </div>
      </div>
      <template v-if="loading.implementations">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner big variant="primary" />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              <h5>Recomendações</h5>
              <b-badge
                variant="danger"
                class="ml-1 d-flex align-items-center"
                pill
                style="height: 20px"
                >{{ implementations.length }}</b-badge
              >
            </div>
          </b-col>
          <b-col cols="12" md="12" style="overflow: overlay">
            <label v-if="v$.implementations.$invalid" class="text-danger">
              É necessário informar pelo menos uma recomendação
            </label>
            <table v-else class="financial-planning-table">
              <thead style="background: none !important">
                <tr style="background: none !important">
                  <th width="60%">Recomendação</th>
                  <th width="40%">Prazo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="implementation in implementations">
                  <td>
                    {{ implementation.recommendation }}
                  </td>
                  <td>
                    {{ implementation.deadline }}
                    {{ implementation.deadline_type ? implementation.deadline_type.toLowerCase() : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card class="step-card-validation">
      <div class="d-flex justify-content-between align-items-center">
        <div class="financial-step-title m-1">
          <span class="ml-1">Finalização</span>
        </div>
      </div>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <label
              >Por favor, revise todas as informações preenchidas antes de
              finalizar. Certifique-se de que todos os dados estão corretos
              antes de seguir.</label
            >
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              class="end-revision-button"
              variant="primary"
              @click="finish"
            >
              Finalizar
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import _ from "lodash";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf, minValue, maxValue } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import * as investmentLevelTypes from "@/constants/investment_level_types";
import maritalStatuses from "@/constants/marital_statuses_types";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  props: {
    toStep: Function,
  },
  directives: {
    money: VMoney,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: {
        planning: false,
        efficiency: false,
        financialManagement: false,
        risks: false,
        futureEarnings: false,
        savings: false,
        assetManagement: false,
        taxes: false,
        implementations: false,
        objectives: false,
      },
      planningStatus: undefined,
      efficiency: {},
      cashFlowIncome: undefined,
      financialManagement: {},
      risks: {},
      objectives: {},
      futureEarnings: {},
      savings: {},
      assetManagement: {},
      taxes: {},
    };
  },
  validations() {
    return {
      efficiency: {
        max_saving_amount: { required, minValue: minValue(0.01) },
        cut_expenses_oportunities: { required },
        revenue_oportunities: { required },
      },
      financialManagement: {
        using_customer_cash_flow: { required },
        orientations: { required },
        retirement_age: { required, minValue: minValue(20), maxValue: maxValue(99) }
      },
      risks: {
        monthly_expenses_sum: { required, minValue: minValue(0.01) },
        death_indemnity: { required, minValue: minValue(0.01) },
        cost_of_new_protections: { required, minValue: minValue(0.01) },
        new_client_savings_amount: { required, minValue: minValue(0.01) },
        protection_need: (value) => this.hasRiskProtectionNeed(),
      },
      risksProtections: { required },
      futureEarnings: {
        retirement_savings_amount: { required, minValue: minValue(0.01) },
        future_savings_amount: { required, minValue: minValue(0.01) },
        retirement_income_advice: { required },
        pension_investment_advice: { required },
      },
      savings: {
        months: { required, minValue: minValue(1) },
        recommended_amount: { required, minValue: minValue(0.01) },
        allocation_orientations: { required },
      },
      assetManagement: {
        profile_level: { required },
        management_rebalancing_metodology_id: { required },
        orientations: { required },
        investment_goals: (value) => this.hasInvestmentGoals(),
      },
      taxes: {
        income_tax_type: { required },
        pgbl_possibility: { required },
        pgbl_anual: {
          required: requiredIf(() => this.taxes.pgbl_possibility == 1),
          minValue: (value) => {
            if (this.taxes.pgbl_possibility == 1) {
              return value > 0;
            }
            return true;
          },
        },
        pgbl_economy: {
          required: requiredIf(() => this.taxes.pgbl_possibility == 1),
          minValue: (value) => {
            if (this.taxes.pgbl_possibility == 1) {
              return value > 0;
            }
            return true;
          },
        },
        marital_status: { required },
        inheritance_successed: { required, minValue: minValue(0.00) },
        inventory_percentage: { required },
        inventory_amount: { required, minValue: minValue(0.00) },
      },
      implementations: { required },
    };
  },
  computed: {
    ...mapGetters({
      risksProtections: types.RISK_PROTECTIONS,
      implementations: types.IMPLEMENTATIONS,
    }),
    percentageOfIncome: function () {
      const savingAmount = this.efficiency.max_saving_amount;
      if (this.cashFlowIncome && this.cashFlowIncome > 0 && savingAmount > 0) {
        return ((savingAmount / this.cashFlowIncome) * 100).toFixed(0);
      }
      return 0;
    },
    protectionNeeds: function () {
      let needs = [
        { label: "Monitorar a Saúde", value: this.risks.health_monitoring },
        {
          label: "Garantir os Dependentes",
          value: this.risks.dependent_coverage,
        },
        {
          label: "Proteção de bens - Alto valor",
          value: this.risks.high_value_asset_protection,
        },
        { label: "Recuperar a Saúde", value: this.risks.health_recovery },
        {
          label: "Responsabilidade Profissionais",
          value: this.risks.professional_liabilities,
        },
        {
          label: "Proteger a força de trabalho",
          value: this.risks.professional_force_protection,
        },
        {
          label: "Proteção de bens - Imóvel",
          value: this.risks.real_estate_protection,
        },
      ];
      return _.filter(needs, { value: 1 });
    },
    investmentGoals: function () {
      return [
        {
          title: "Pós fixado",
          value: this.assetManagement.post_fixed,
          color: "#EC2020",
        },
        {
          title: "Inflação",
          value: this.assetManagement.inflation_rate,
          color: "#FFA413",
        },
        {
          title: "Prefixado",
          value: this.assetManagement.pre_fixed,
          color: "#7B77CA",
        },
        {
          title: "Multimercado",
          value: this.assetManagement.multimarket,
          color: "#FF4501",
        },
        {
          title: "Alternativos",
          value: this.assetManagement.alternatives,
          color: "#13FFFF",
        },
        {
          title: "Internacional",
          value: this.assetManagement.international,
          color: "#1147D9",
        },
        {
          title: "Renda variável",
          value: this.assetManagement.variable_income,
          color: "#EA06B5",
        },
      ];
    },
    profileLevelName: function () {
      if (
        this.assetManagement.profile_level === investmentLevelTypes.CONSERVATIVE
      ) {
        return "Conservador";
      } else if (this.profile_level === investmentLevelTypes.AGGRESSIVE) {
        return "Agressivo";
      }
      return "Moderado";
    },
    maritalStatus: function () {
      const status = _.find(maritalStatuses, {
        name: this.taxes.marital_status,
      });
      if (status) {
        return status.label;
      }
      return this.taxes.marital_status;
    },
  },
  mounted() {
    this.loading.efficiency = true;
    this.getEfficiency(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.efficiency = response.data;
        }
      })
      .catch((err) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de eficiência. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.efficiency = false;
      });
    this.getCashFlowIncome(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.cashFlowIncome = response.data.value;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de eficiência. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    this.loading.financialManagement = true;
    this.getFinancialManagement(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.financialManagement = response.data;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de gestão financeira. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.financialManagement = false;
      });
    this.loading.risks = true;
    this.getRisks(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.risks = response.data;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.risks = false;
      });
    this.loading.risksProtections = true;
    this.getRisksProtections(this.$route.params.id)
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.risksProtections = false;
      });
    this.loading.futureEarnings = true;
    this.getFutureEarnings(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.futureEarnings = response.data;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de eficiência. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.futureEarnings = false;
      });
    this.loading.savings = true;
    this.getSavings(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.savings = response.data;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.savings = false;
      });
    this.loading.assetManagement = true;
    this.getAssetManagement(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.assetManagement = response.data;
        }
      })
      .catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.assetManagement = false;
      });
    this.loading.taxes = true;
    this.getTaxes(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.taxes = response.data;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de planejamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.taxes = false;
      });
    this.loading.implementations = true;
    this.getImplementations(this.$route.params.id)
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as implementações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.implementations = false;
      });
    this.loading.objectives = true;
    this.getCustomerObjectives(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.objectives = response.data;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as informações. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.objectives = false;
      });
  },
  methods: {
    ...mapActions({
      getEfficiency: types.GET_EFFICIENCY,
      getCashFlowIncome: types.GET_CASH_FLOW_INCOME,
      getFinancialManagement: types.GET_FINANCIAL_MANAGEMENT,
      getRisks: types.GET_RISKS,
      getRisksProtections: types.GET_RISKS_PROTECTIONS,
      getFutureEarnings: types.GET_FUTURE_EARNINGS,
      getSavings: types.GET_SAVINGS,
      getAssetManagement: types.GET_ASSET_MANAGEMENT,
      getTaxes: types.GET_TAXES,
      getImplementations: types.GET_IMPLEMENTATIONS,
      finishPlanning: types.FINISH_PLANNING,
      editPlanning: types.EDIT_PLANNING,
      getPlanningStatus: types.GET_PLANNING_STATUS,
      getCustomerObjectives: types.GET_OBJECTIVES,
    }),
    getStatus() {
      this.loading.planning = true;
      this.getPlanningStatus(this.$route.params.id)
        .then((response) => {
          if (response.data) {
            this.planningStatus = response.data;
          }
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter as informações de eficiência. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.planning = false;
        });
    },
    hasRiskProtectionNeed() {
      return (
        this.risks &&
        (this.risks.health_monitoring ||
          this.risks.dependent_coverage ||
          this.risks.high_value_asset_protection ||
          this.risks.health_recovery ||
          this.risks.professional_liabilities ||
          this.risks.professional_force_protection ||
          this.risks.real_estate_protection)
      );
    },
    hasInvestmentGoals() {
      return (
        this.assetManagement &&
        (this.assetManagement.post_fixed > 0 ||
          this.assetManagement.inflation_rate > 0 ||
          this.assetManagement.pre_fixed > 0 ||
          this.assetManagement.multimarket > 0 ||
          this.assetManagement.alternatives > 0 ||
          this.assetManagement.international > 0 ||
          this.assetManagement.variable_income > 0)
      );
    },
    async finish() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        const errors = this.getErrorSections();
        this.$swal({
          title: "Ooops!",
          html:
            "<p>Ocorreu um erro ao finalizar.</p> <p>Você deve solucionar as inconsistências apontadas nas seguintes seções:</p>" +
            errors,
          icon: "error",
          width: "40vw",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
          buttonsStyling: false,
        });
        return;
      }
      this.loading.planning = true;
      this.finishPlanning(this.$route.params.id)
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao finalizar o plano. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    getErrorSections() {
      var sections = "<b>";
      if (this.v$.efficiency.$error) {
        sections += "Poupar eficiente - passo 1<br>";
      }
      if (this.v$.financialManagement.$error) {
        sections += "Gestão financeira - passo 2<br>";
      }
      if (this.v$.risks.$error || this.v$.risksProtections.$error) {
        sections += "Gestão de riscos - passo 3<br>";
      }
      if (this.v$.risksProtections.$error) {
        sections += "Proteção detalhada é obrigatório - passo 3<br>";
      }
      if (this.v$.futureEarnings.$error) {
        sections += "Garantia de renda futura - passo 4<br>";
      }
      if (this.v$.savings.$error) {
        sections += "Reserva de curto prazo - passo 5<br>";
      }
      if (this.v$.assetManagement.$error) {
        sections += "Gestão de ativos - passo 7<br>";
      }
      if (this.v$.taxes.$error) {
        sections += "Planejamento tributário e sucessório - passo 8<br>";
      }
      if (this.v$.implementations.$error) {
        sections += "Implementação<br>";
      }
      sections += "</b>";
      return sections;
    },
    formatDate(date) {
      if (!date) return "";
      return new Intl.DateTimeFormat("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(date));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "~@core/scss/base/components/include"; // Components includes

.step-card-validation {
  min-height: 125px !important;
  border-radius: 15px;
  .financial-step-title {
    height: 20px !important;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  button {
    color: $body-color;
  }
  .col-12 {
    label {
      font-size: 12px;
      font-weight: bolder;
      margin-bottom: 0;
      display: block;
    }
    span {
      font-size: 12px;
    }
  }
  .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 10px;
    background-color: $primary;
  }
  .wallet-goal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    font-weight: 600;
    .goal-title {
      display: flex;
      align-items: center;
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
  .end-revision-button {
    margin-top: 2rem;
    border-radius: 10px;
    width: 200px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.step-error {
    background-color: #ff610112;
    border: 2px solid #ff6200;
  }
}

.dark-layout .step-card-validation button {
  color: #5a698d;
}
</style>
