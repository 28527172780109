<template>
  <div>
    <b-sidebar
      id="wallet-investment-goal"
      sidebar-class="sidebar-lg"
      :visible="walletInvestmentGoalsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateWalletInvestmentGoalsSidebar({ visible: val })"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="planning-sidebar-header px-2 py-1">
          <h4>Metas de carteira</h4>
        </div>
        <div class="m-2">
          <b-row>
            <b-col cols="12" class="mb-2">
              <span
                >Escolha a porcentagem para cada classe que encaixe dentro do
                perfil do cliente</span
              >
            </b-col>
            <template v-for="goal in investmentGoals">
              <b-col cols="12" class="mt-2">
                <div>
                  <b>
                    <label>{{ goal.title }}</label>
                    <span class="float-right"
                      >{{ investmentGoalsData[goal.field] }}%</span
                    >
                  </b>
                </div>
                <div>
                  <vue-slider
                    class="primary ml-1 mb-1"
                    v-model="investmentGoalsData[goal.field]"
                    :drag-on-click="true"
                    :max="100"
                    direction="ltr"
                    style="margin: 0"
                  />
                </div>
              </b-col>
            </template>
            <b-col>
              <div class="d-flex flex-column align-items-end">
                <strong class="text-primary"
                  >Total: {{ percentageTotal }}%</strong
                >
                <strong v-if="invalidPercentage" class="text-danger mt-1"
                  >ATENÇÃO: percentual inválido (maior que 100%)</strong
                >
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="planning-sidebar-bottom px-1">
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button variant="outline-primary" @click="hide">
              Fechar
            </b-button>
          </b-col>
          <b-col cols="12" md="6" class="mt-2 planing-sidebar-button">
            <b-button
              variant="primary"
              :disabled="invalidPercentage"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import VueSlider from "vue-slider-component";
import * as types from "../store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    VueSlider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  props: {
    investmentGoals: {
      type: Array,
      default: [],
    },
    investmentGoalsData: {
      type: Object,
      default: {},
    },
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  computed: {
    ...mapGetters({
      walletInvestmentGoalsSidebar: types.WALLET_INVESTMENT_GOALS_SIDEBAR,
    }),
    percentageTotal() {
      let total = 0;
      for (const goal in this.investmentGoalsData) {
        total += this.investmentGoalsData[goal];
      }
      return total;
    },
    invalidPercentage() {
      return this.percentageTotal > 100;
    },
  },
  methods: {
    ...mapMutations({
      mutateWalletInvestmentGoalsSidebar:
        types.MUTATE_WALLET_INVESTMENT_GOALS_SIDEBAR,
    }),
    save() {
      if (this.percentageTotal > 100) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Erro",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "A soma das porcentagens não pode ser maior que 100%",
          },
        });
        return;
      }
      this.walletInvestmentGoalsSidebar.saveAction();
      this.mutateWalletInvestmentGoalsSidebar({ visible: false });
    },
  },
};
</script>

<style lang="scss">
$themeColor: #f26237;
@import "~vue-slider-component/lib/theme/default.scss";

.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
