<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Planejamento tributário e sucessório</h4>
            <span class="text-muted h5">Passo 08</span>
          </div>
        </div>
      </b-card-title>
      <b-card-body>
        <template v-if="loading">
          <b-row class="justify-content-md-center mt-2">
            <b-spinner big variant="primary" />
          </b-row>
        </template>
        <b-card-body v-else>
          <b-row class="mb-2">
            <b-col cols="12" md="6">
              Qual deve ser o tipo de declaração de imposto de renda do seu
              cliente?
              <br />
            </b-col>
            <b-col cols="12" md="6">
              <v-select
                id="income_tax_type"
                v-model="incomeTaxType"
                :reduce="(incomeTaxType) => incomeTaxType.name"
                :options="incomeTaxTypes"
                label="name"
                @input="saveData"
              />
              <div class="invalid-feedback">
                <span v-if="v$.incomeTaxType.required.$invalid">
                  Você deve selecionar um tipo de declaração
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mb-2 mt-2">
              <div class="boder-col" />
            </b-col>
            <b-col cols="6" md="4">
              <b-form-group
                label="Existe a possibilidade para PGBL"
                label-for="pgbl-possibility"
                label-size="sm"
              >
                <b-form-checkbox
                  v-model="pgblPossibility"
                  name="pgbl-possibility"
                  switch
                  @change="handleCheckboxChange"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" md="4">
              <b-form-group
                label="Qual o Valor? (ao ano)"
                label-for="pgbl-anual"
                label-size="sm"
              >
                <b-form-input
                  id="pgbl-anual"
                  v-model="pgblAnual"
                  v-money="moneyFormat"
                  @blur="saveData"
                  :disabled="!pgblPossibility"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6" md="4">
              <b-form-group
                label="Economia com PGBL"
                label-for="pgbl-economy"
                label-size="sm"
              >
                <b-form-input
                  id="pgbl-economy"
                  v-model="pgblEconomy"
                  v-money="moneyFormat"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="pt-3">
            <b-col cols="12" class="mb-2 mt-2">
              <div class="boder-col" />
            </b-col>
            <b-col cols="12" md="6">
              Estado civil do seu cliente. Se casado ou União qual o regime de
              casamento?
            </b-col>
            <b-col cols="12" md="6">
              <v-select
                id="marital_status"
                v-model="maritalStatus"
                :reduce="(maritalStatus) => maritalStatus.name"
                :options="maritalStatuses"
                label="label"
                @input="saveData"
              />
              <div class="invalid-feedback">
                <span v-if="v$.maritalStatus.required.$invalid">
                  Você deve selecionar um tipo de estado civil
                </span>
              </div>
            </b-col>
          </b-row>

          <b-row class="pt-3">
            <b-col cols="12" md="6">
              Valor do patrimônio do cliente em caso de sucessão:
            </b-col>
            <b-col cols="12" md="6">
              <b-form-input
                v-money="moneyFormat"
                v-model="inheritanceSuccessed"
                @blur="saveData"
              />
            </b-col>
          </b-row>

          <b-row class="pt-3 mb-3">
            <b-col cols="12" md="6">
              <b-form-group
                label="Estimativa de custo de Inventário:"
                label-for="pgbl-possibility"
                label-size="sm"
              >
                <div class="slider-container">
                  <vue-slider
                    class="primary"
                    v-model="inventoryPercentage"
                    :max="100"
                    :direction="dir"
                    tooltip="none"
                    :marks="{ 0: '0%', 100: '100%' }"
                    @change="saveData"
                  >
                    <template v-slot:tooltip="{ value }">
                      <div v-if="value <= 99" class="custom-tooltip">
                        {{ value | toCurrency }}
                      </div>
                      <div v-else class="custom-tooltip">
                        >{{ value | toCurrency }}
                      </div>
                    </template>
                  </vue-slider>
                  <span class="percentage-display"
                    >{{ inventoryPercentage }}%</span
                  >
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Correspondente à:"
                label-for="inventory-amount"
                label-size="sm"
              >
                <b-form-input
                  id="inventory-amount"
                  v-model="inventoryAmount"
                  v-money="moneyFormat"
                  :class="{ 'is-invalid': v$.inventoryAmount.$error }"
                  @blur="saveData"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.inventoryAmount.required.$invalid">
                    Você deve informar um valor válido
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="financial-previous-step">
            <b-button variant="outline-primary" @click="toStep('previous')">
              Voltar
            </b-button>
          </div>
          <div class="financial-next-step">
            <b-button variant="primary" @click="toStep('next')">
              Próximo
            </b-button>
          </div>
        </b-card-body>
      </b-card-body></b-card
    >
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as sharedTypes from "@/modules/shared/store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import { getVueSelectErrorClass } from "@/helpers/validators";
import * as types from "../store/types";
import VueSlider from "vue-slider-component";
import maritalStatuses from "@/constants/marital_statuses_types";
import _ from "lodash";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    VueSlider,
  },
  directives: {
    money: VMoney,
  },
  props: {
    toStep: Function,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      incomeTaxType: undefined,
      pgblPossibility: false,
      pgblAnual: undefined,
      pgblEconomy: undefined,
      maritalStatus: undefined,
      inheritanceSuccessed: undefined,
      inventoryPercentage: 0,
      inventoryAmount: undefined,
      incomeTaxTypes: [
        { id: 1, name: "Completo" },
        { id: 2, name: "Simplificado" },
        { id: 3, name: "Isento" },
      ],
      maritalStatuses,
      dir: "ltr",
      syncComplete: true,
    };
  },
  watch: {
    inventoryPercentage() {
      this.debouncedCalculateInventoryAmount();
    },
    inheritanceSuccessed() {
      this.debouncedCalculateInventoryAmount();
    },
    pgblAnual(value) {
      if (this.pgblPossibility && value) {
        const anualValue = moneyToFloat(value);
        this.pgblEconomy = floatToMoney(anualValue * 0.12);
      }
    },
  },
  validations() {
    return {
      incomeTaxType: { required },
      maritalStatus: { required },
      inventoryAmount: { required },
    };
  },
  computed: {
    moneyFormat() {
      return getBRLFormat();
    },
  },
  created() {
    this.debouncedCalculateInventoryAmount = _.debounce(
      this.calculateInventoryAmount.bind(this),
      100
    );
  },
  mounted() {
    this.loading = true;
    this.getTaxes(this.$route.params.id)
      .then((response) => {
        if (response.data) {
          this.incomeTaxType = response.data.income_tax_type;
          this.pgblPossibility = Boolean(response.data.pgbl_possibility);
          this.pgblAnual = floatToMoney(response.data.pgbl_anual);
          this.pgblEconomy = floatToMoney(response.data.pgbl_economy);
          this.maritalStatus = response.data.marital_status;
          this.inheritanceSuccessed = floatToMoney(
            response.data.inheritance_successed
          );
          this.inventoryPercentage = response.data.inventory_percentage;
          this.inventoryAmount = floatToMoney(response.data.inventory_amount);
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de planejamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      getTaxes: types.GET_TAXES,
      saveTaxes: types.SAVE_TAXES,
    }),
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    handleCheckboxChange() {
      if (!this.pgblPossibility) {
        this.pgblAnual = "0,00";
        this.pgblEconomy = "0,00";
      }
      this.saveData();
    },
    calculateInventoryAmount() {
      this.syncComplete = false;
      if (this.inheritanceSuccessed) {
        const inheritanceValue = moneyToFloat(this.inheritanceSuccessed);
        this.inventoryAmount = floatToMoney(
          (this.inventoryPercentage / 100) * inheritanceValue
        );
      }
      this.syncComplete = true;
      this.saveData();
    },
    saveData() {
      if (!this.syncComplete) return;
      const {
        incomeTaxType,
        pgblPossibility,
        pgblAnual,
        pgblEconomy,
        maritalStatus,
        inheritanceSuccessed,
        inventoryPercentage,
        inventoryAmount,
      } = this;
      const payload = {
        income_tax_type: incomeTaxType,
        pgbl_possibility: pgblPossibility ? 1 : 0,
        pgbl_anual: moneyToFloat(pgblAnual),
        pgbl_economy: moneyToFloat(pgblEconomy),
        marital_status: maritalStatus,
        inheritance_successed: moneyToFloat(inheritanceSuccessed),
        inventory_percentage: inventoryPercentage,
        inventory_amount: moneyToFloat(inventoryAmount),
        customer_id: this.$route.params.id,
      };
      this.saveTaxes(payload).catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao salvar as informações de planejamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
  },
};
</script>

<style lang="scss">
.boder-col {
  border-top: 1px solid #5a698d;
}
$themeColor: #f26237;
@import "~vue-slider-component/lib/theme/default.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-group {
  margin-bottom: 0;
}

.slider-container {
  position: relative;
}

.percentage-display {
  position: absolute;
  top: -10px;
  right: 0;
  font-weight: bold;
  color: white;
  font-size: 14px;
}
</style>
