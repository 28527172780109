<template>
  <div
    class="financial-planning-step"
    @click="clickAction"
    :class="{ 'click-disabled': disabled }"
  >
    <div class="check-icon" :class="{ selected: selected }">
      <template v-if="stepDone">
        <feather-icon
          v-if="stepDone"
          class="check-green"
          icon="CheckIcon"
          size="16"
          style="position: absolute"
        />
        <img
          :src="require('@/assets/images/svg/icn_circle_green.svg')"
          width="30px"
        />
      </template>
      <template v-else>
        <span class="number">{{ number }}</span>
        <img
          v-if="selected"
          :src="require('@/assets/images/svg/icn_circle_bordered.svg')"
          width="30px"
        />
        <img
          v-else
          :src="require('@/assets/images/svg/icn_circle.svg')"
          width="30px"
        />
      </template>
    </div>
    <div class="title" :class="{ selected: selected }">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: Boolean,
    disabled: Boolean,
    number: Number,
    title: String,
    stepDone: Boolean,
    click: Function,
  },
  methods: {
    clickAction() {
      if (!this.disabled) {
        this.click(this.number);
      }
    },
  },
};
</script>

<style lang="scss">
.financial-planning-step {
  display: flex;
  align-items: center;
  margin: 18px 0;
  cursor: pointer;

  .title {
    padding-left: 1rem;
    font-size: 13px;
    &.selected {
      font-weight: 600;
    }
  }

  .check-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    &.selected {
      background-color: transparent !important;
    }
    .number {
      position: absolute;
      color: white;
      font: normal normal 600 16px Montserrat;
    }
  }
  .check-green {
    filter: invert(60%) sepia(63%) saturate(3263%) hue-rotate(86deg)
      brightness(111%) contrast(119%) !important;
    max-width: 75%;
  }
}
.click-disabled {
  cursor: default !important;
}
</style>
