<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Garantia de renda futura</h4>
            <span class="text-muted h5">Passo 04</span>
          </div>
        </div>
      </b-card-title>
      <template v-if="loading">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner
            big 
            variant="primary"
          />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6">
            Montante da reserva necessária para a aposentadoria:
          </b-col>
          <b-col cols="12" md="6">
            <b-form-input
              v-money="moneyFormat"
              v-model="pensionReserve"
              :class="{ 'is-invalid': v$.pensionReserve.$error }"
              @blur="saveData"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.pensionReserve.required.$invalid 
                "
              >
                Você deve informar um valor válido
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col cols="12" md="6">
            Expectativa da reserva futura do cliente:
          </b-col>
          <b-col cols="12" md="6">
            <b-form-input
              v-money="moneyFormat"
              v-model="savingsEstimate"
              :class="{ 'is-invalid': v$.savingsEstimate.$error }"
              @blur="saveData"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.savingsEstimate.required.$invalid 
                "
              >
                Você deve informar um valor válido
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col cols="12" md="6">
            Orientação para o cliente em relação à expectativa de renda na idade de aposentadoria desejada:
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
          <b-form-textarea
            v-model="retirementIncomeAdvice"
            @blur="saveData"
          />
          </b-col>
        </b-row>

        <b-row class="pt-3">
          <b-col cols="12" md="6">
            Orientação do planejador em relação a investir em previdência privada:
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
          <b-form-textarea
            v-model="privatePensionGuidance"
            @blur="saveData"
          />
          </b-col>
        </b-row>
        
        <div class="financial-previous-step">
          <b-button
            variant="outline-primary"
            @click="toStep('previous')"
          > Voltar
          </b-button>
        </div>
        <div class="financial-next-step">
          <b-button
            variant="primary"
            @click="toStep('next')"
          > Próximo
          </b-button>
        </div>
      </b-card-body>
      
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect
  },
  props: {
    toStep: Function
  },
  directives: {
    money: VMoney,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin, v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      id: undefined,
      pensionReserve: undefined,
      savingsEstimate: undefined,
      retirementIncomeAdvice: undefined,
      privatePensionGuidance: undefined,
    };
  },
  validations() {
    return {
      pensionReserve: {required},
      savingsEstimate: {required}
    }
  },
  mounted(){
    this.loading = true
    this.getFutureEarnings(this.$route.params.id)
      .then((response) => {
        if(response.data){
          this.pensionReserve = response.data.retirement_savings_amount;
          this.savingsEstimate = response.data.future_savings_amount;
          this.retirementIncomeAdvice = response.data.retirement_income_advice;
          this.privatePensionGuidance = response.data.pension_investment_advice;
          this.id = response.data.id;
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as informações de eficiência. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    moneyFormat: function () {
      return getBRLFormat();
    }
  },
  methods: {
    ...mapActions({
      saveFutureEarnings: types.SAVE_FUTURE_EARNINGS,
      getFutureEarnings: types.GET_FUTURE_EARNINGS
    }),
    saveData(){
      const { pensionReserve, savingsEstimate, retirementIncomeAdvice, privatePensionGuidance, id } = this
      const payload = {
        id: id,
        retirement_savings_amount: moneyToFloat(pensionReserve),
        future_savings_amount: moneyToFloat(savingsEstimate),
        retirement_income_advice: retirementIncomeAdvice,
        pension_investment_advice: privatePensionGuidance,
        customer_id: this.$route.params.id
      }
      this.saveFutureEarnings(payload)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar as informações de renda futura. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
    },
  },
};
</script>

<style lang="scss" scoped>

</style>