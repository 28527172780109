<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-card-title>
        <div class="financial-step-title m-1">
          <div class="m-1">
            <h4>Gestão financeira</h4>
            <span class="text-muted h5">Passo 02</span>
          </div>
        </div>
      </b-card-title>
      <template v-if="loading">
        <b-row class="justify-content-md-center mt-2">
          <b-spinner
            big 
            variant="primary"
          />
        </b-row>
      </template>
      <b-card-body v-else>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center custom-checkbox-container">
              <b-form-checkbox
                v-model="usingCustomerCashFlow"
                name="using-customer-cash-flow"
                switch
                @change="handleCheckboxChange"
              />
              <span class="custom-checkbox-text ms-2">Cliente faz Fluxo de Caixa Corretamente?</span>
            </div>
            <br>
            <small class="text-muted">
              Selecione esta opção se o cliente utilize ferramentas para gerenciar
              suas finanças. Caso contrário, recomende a implementação do Fluxo de Caixa do Oráculo.
            </small>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="!hasCashFlow"
              class="alert-warning d-flex align-items-center"
            >
              <img
                src="../../../assets/images/icons/exclamation-triangle.svg"
                alt="Warning Icon"
                class="icon-warning"
              >
              <span class="text-warning">Não utiliza o Fluxo de Caixa Futuro</span>
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col
            cols="12"
            md="6"
          >
            Orientações para o cliente
            <br>
            <small class="text-muted">
              Forneça recomendações detalhadas sobre como o cliente pode utilizar o fluxo de caixa de forma mais eficiente, sugerindo ajustes específicos para otimizar o controle financeiro e identificar áreas de melhoria.
            </small>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-textarea
              v-model="orientations"
              @blur="saveData"
            />
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col
            cols="12"
            md="6"
          >
            Com quantos anos o cliente gostaria de se aposentar?
            <br>
            <small class="text-muted">
              Esta data será usada para gerar a Linha do Futuro (Valor entre 20 e 99)
            </small>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
          <b-form-input
            v-model="retirementAge"
            type="number"
            @blur="saveData"
          />
          </b-col>
        </b-row>
        <div class="financial-previous-step">
          <b-button
            variant="outline-primary"
            @click="toStep('previous')"
          > Voltar
          </b-button>
        </div>
        <div class="financial-next-step">
          <b-button
            variant="primary"
            @click="toStep('next')"
          > Próximo
          </b-button>
        </div>
      </b-card-body>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useVuelidate from '@vuelidate/core'
import { required, between, requiredIf } from '@vuelidate/validators'
import useAppConfig from '@core/app-config/useAppConfig'
import * as types from '../store/types'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  props: {
    toStep: Function,
  },
  setup() {
    const { skin } = useAppConfig()
    return { toast: useToast(), skin, v$: useVuelidate() }
  },
  data() {
    return {
      loading: false,
      usingCustomerCashFlow: false,
      orientations: undefined,
      retirementAge: undefined,
      hasCashFlow: false,
    }
  },
  validations() {
    return {
      usingCustomerCashFlow: { required },
    }
  },
  mounted() {
    this.loading = true
    this.getFinancialManagement(this.$route.params.id)
      .then(response => {
        if (response.data) {
          console.log('response.data', response.data)
          this.usingCustomerCashFlow = Boolean(response.data.using_customer_cash_flow)
          this.orientations = response.data.orientations
          this.retirementAge = response.data.retirement_age
          this.hasCashFlow = Boolean(response.data.has_cash_flow)
        }
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao obter as informações de gestão financeira. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    ...mapActions({
      getFinancialManagement: types.GET_FINANCIAL_MANAGEMENT,
      saveFinancialManagement: types.SAVE_FINANCIAL_MANAGEMENT,
    }),
    handleCheckboxChange() {
      this.saveData()
    },
    saveData() {
      const { usingCustomerCashFlow, orientations, retirementAge } = this
      const payload = {
        using_customer_cash_flow: usingCustomerCashFlow ? 1 : 0,
        orientations,
        retirement_age :retirementAge,
        customer_id: this.$route.params.id,
      }
      this.saveFinancialManagement(payload)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao salvar as informações de gestão financeira. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.alert-warning {
  background: rgba(255, 89, 0, 0.28) !important;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.icon-warning {
  width: 33px;
  height: 33px;
}

.text-warning {
  font: normal normal 600 14px/18px Montserrat;
  color: #ff5900 !important;
  font-weight: bold;
}
</style>
